import logger from "../log";

type Retry = <T>(
    fn: () => Promise<T>,
    key: string,
    delay: number,
    retries: number,
    retryBreak?: (reason?: unknown) => boolean
) => Promise<T>;

const requestMap = new Map<string, boolean>();

export function registerRetry(key: string) {
    logger.info(`Attempting to register retry cancellation token: ${key}`);
    requestMap.set(key, false);
    logger.info(`Registered retry cancellation token: ${key} successfully`);
}

export function cancelRetry(key: string) {
    logger.info(`Attempting to cancel retry ${key}`);
    requestMap.set(key, true);
    logger.info(`Cancellation token set for ${key}`);
}

export const retry: Retry = (fn, key, delay, retries, retryBreak) => new Promise((resolve, reject) => {
    const cancelled = !requestMap.has(key) || requestMap.get(key);
    if (cancelled) {
        logger.info(`Retry cancelled for ${key}: Resetting cancelled state and returning early.`);
        return;
    }

    fn().then(resolve).catch((err) => {
        if (retryBreak && retryBreak(err)) {
            return reject(err);
        }
        if (retries > 0) {
            return new Promise((r) => { setTimeout(r, delay); })
                .then(retry.bind(null, fn, key, delay, retries - 1, retryBreak))
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .then(resolve)
                .catch(reject);
        }
        return reject(err);
    });
});
