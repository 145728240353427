const KEY = "LATEST_SHARED_CONTENT";

export const setLastSeenLocal = (id: number | null) => {
    if (id) {
        window.localStorage.setItem(KEY, id.toString());
    }
};

export const getLastSeenLocal = () => {
    const storageValue = window.localStorage.getItem(KEY);
    if (storageValue) {
        return Number.parseInt(storageValue, 10);
    }

    return null;
};
