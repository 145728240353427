import * as React from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SportPicker from "../SportPicker";

import useDialog from "./useDialog";

export default function SportSelectorWelcome(): JSX.Element {
    const { setDialogType } = useDialog();
    const [disabled, setDisabled] = React.useState(true);

    return (
        <>
            <DialogTitle
                sx={{ p: 2 }}
                color="primary.main"
            >
                What would you like to play?
            </DialogTitle>
            <DialogContent>
                <Stack
                    spacing={2}
                >
                    <Typography>
                        Choose a sport from the list below.
                    </Typography>
                    <Typography>
                        You can change this any time from the menu at the top of home screen.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    spacing={1}
                    sx={{
                        width: "100%",
                    }}
                >
                    <SportPicker
                        backdrop="light"
                        fullWidth
                        showOnlyCompatible
                        afterSportChanged={() => { setDisabled(false); }}
                    />
                    <Button
                        fullWidth
                        disabled={disabled}
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => setDialogType(null)}
                    >
                        Let&apos;s Go!
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}
