import * as React from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";

import logger, { useLogMountTime } from "../../log";
import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import SupportLink from "../common/SupportLink";
import { useStatus } from "../hooks/status";

import useDialog from "./useDialog";

export default function HardwareFailureDialog(): JSX.Element {
    useLogMountTime("HardwareFailureDialog");

    const { status, error } = useStatus();
    const { setDialogProps, setDialogType } = useDialog();
    const [loading, setLoading] = React.useState(false);
    const retryCount = React.useRef(0);
    const recheckCount = React.useRef<number | undefined>();
    const [message, setMessage] = React.useState("Attempting to automatically resolve the issue.");
    const [showShutdown, setShowShutdown] = React.useState(false);
    const [showRetry, setShowRetry] = React.useState(true);
    const [faultCleared, setFaultCleared] = React.useState(false);

    const clearFault = React.useCallback(async () => {
        recheckCount.current = 0;
        retryCount.current += 1;
        setLoading(true);

        try {
            logger.info("Attempting to clear fault from HardwareFailureDialog");
            await pairedFetchApi(status?.clientId, "/api/clear-fault", "POST");
        } catch (ex) {
            logFetchError(ex, "Failed to clear fault from HardwareFailureDialog");
            setMessage("We can't automatically fix this issue. Please shut the trainer down and restart it.");
            setShowRetry(false);
            setShowShutdown(true);
        }
    }, [status?.clientId]);

    React.useEffect(() => {
        if (typeof recheckCount.current !== "undefined") {
            recheckCount.current += 1;
            logger.info(`recheckCount: ${recheckCount.current}`);
            if (status?.fault === null && (
                status?.trainer.state === "UNCALIBRATED" || status?.trainer.state === "IDLE")) {
                logger.info("Clearing hardware fault dialog.");
                setLoading(false);
                setShowRetry(false);
                setShowShutdown(false);
                setFaultCleared(true);
            } else if (recheckCount.current > 2) {
                setMessage("Please click retry which will attempt to fix the issue.");
                recheckCount.current = undefined;
                setLoading(false);
                if (retryCount.current > 2) {
                    setMessage("We can't seem to automatically recover. Please shut down and restart the trainer.");
                    setShowRetry(false);
                    setShowShutdown(true);
                }
            }
        }
    }, [setDialogType, status]);

    React.useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    const onClose = React.useCallback(() => {
        setDialogType("Device");
    }, [setDialogType]);

    React.useEffect(() => {
        setDialogProps({ onClose });
    }, [onClose, setDialogProps]);

    React.useEffect(() => {
        void clearFault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (faultCleared) {
            setMessage("The issue has been resolved.");
            const timeout = setTimeout(() => {
                setDialogType(null);
            }, 3000);

            return () => clearTimeout(timeout);
        }

        return () => null;
    }, [faultCleared, setDialogType]);

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={onClose}>
                Hardware Fault
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack sx={{ width: "100%" }}>
                    {showRetry && (
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            size="large"
                            fullWidth
                            loading={loading}
                            onClick={clearFault}
                            sx={{ mb: 2 }}
                        >
                            Retry
                        </LoadingButton>
                    )}
                    {showShutdown && (
                        <Button
                            variant="contained"
                            color="info"
                            size="large"
                            fullWidth
                            disabled={loading}
                            onClick={() => setDialogType("NoConfirmShutdown")}
                            sx={{ mb: 2 }}
                        >
                            Shut Down
                        </Button>
                    )}
                    <SupportLink preText="Need more help?">
                        Click here.
                    </SupportLink>
                </Stack>
            </DialogActions>
        </>
    );
}
