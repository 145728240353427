import * as React from "react";

import useTheme from "@mui/material/styles/useTheme";

const CLIP_PATH_ID = "thermometer-clip-path";

interface Props {
    ticks: number;
    tickStyle: {
        width: number;
        height: number;
        radius: number;
        margin: number;
    };
    min: number;
    max: number;
    value: number;
}

export default function CampaignClubThermometer({
    ticks, tickStyle, min, max, value,
}: Props): JSX.Element {
    const theme = useTheme();
    const height = React.useMemo(() => {
        const { height: tickHeight, margin: tickMargin } = tickStyle;
        return +((ticks * (tickHeight + tickMargin)) - tickMargin).toFixed() || 0;
    }, [ticks, tickStyle]);

    const scaleHeight = React.useMemo(() => {
        const fullness = value / (max - min);
        return +(fullness * height).toFixed() || 0;
    }, [min, max, value, height]);

    const clipPath = React.useMemo(() => {
        const keys = [...Array(ticks || 0).keys()];

        return keys.map((item) => (
            <rect
                key={item}
                height={tickStyle.height}
                width={tickStyle.width}
                rx={tickStyle.radius}
                x="0"
                y={(tickStyle.height + tickStyle.margin) * item}
            />
        ));
    }, [ticks, tickStyle]);

    return (
        <svg width={`${tickStyle.width}`} height={`${height}`}>
            <defs>
                <clipPath id={CLIP_PATH_ID}>
                    {clipPath}
                </clipPath>
            </defs>
            <g
                clipPath={`url(#${CLIP_PATH_ID})`}
                style={{ transform: `rotate(-180deg) translate(-${tickStyle.width}px, -${height}px)` }}
            >
                <rect
                    fill={theme.palette.grey[300]}
                    height="100%"
                    width="100%"
                    x="0"
                    y="0"
                />
                <rect
                    className="scale"
                    style={{ transition: "height 0.5s" }}
                    fill={theme.palette.primary.main}
                    height={`${scaleHeight}`}
                    width="100%"
                    x="0"
                    y="0"
                />
            </g>
        </svg>
    );
}
