import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { BackgroundImageLookup, KnownImage } from "./NavigationCardImages";
import { useSelectedSport } from "./context/sport";

export type NavOperation = "push" | "replace";

export interface NavigationCardDef {
    feature?: string;
    title: string;
    description: string;
    link: string;
    waitCondition?: boolean;
    waitMessage?: string;
    operation?: NavOperation;
    img?: KnownImage;
}

interface Props {
    card: NavigationCardDef;
}

export default function NavigationCard({ card }: Props): JSX.Element {
    const navigate = useNavigate();
    const { selected: selectedSport } = useSelectedSport();

    const replace = card.operation === "replace";

    let cardContent;
    const doWait = (card.waitCondition || false);

    if (doWait) {
        cardContent = (
            <CardContent
                sx={{
                    position: "absolute",
                    top: 60,
                    left: 0,
                }}
            >
                <Box sx={{
                    display: "flex", flexDirection: "row", textAlign: "center", mt: 2,
                }}
                >
                    <Typography style={{ whiteSpace: "pre-line", marginRight: "5%" }}>
                        {card.waitMessage || "please wait"}
                    </Typography>
                    <CircularProgress size={30} color="info" />
                </Box>
            </CardContent>
        );
    } else {
        cardContent = (
            <CardContent
                sx={{
                    position: "absolute",
                    top: 60,
                    left: 0,
                }}
            >
                <Typography
                    variant="h4"
                    color={card.img ? "white" : "primary.main"}
                    sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        textShadow: card.img ? "#096EE5 4px 0 4px" : "none",
                    }}
                >
                    {card.description}
                </Typography>
            </CardContent>
        );
    }

    return (
        <Card elevation={6} sx={{ height: "160px" }}>
            <CardActionArea disabled={doWait} onClick={() => navigate(card.link, { replace })}>
                {card.img && (
                    <CardMedia
                        image={BackgroundImageLookup[selectedSport][card.img]}
                        sx={{ height: "160px", position: "relative", filter: "blur(2px) brightness(0.75)" }}
                    />
                )}
                <Typography
                    variant="h3"
                    color={card.img ? "white" : "primary.main"}
                    sx={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        lineHeight: "60px",
                        maxWidth: "100%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textShadow: card.img ? "#096EE5 4px 0 4px" : "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                >
                    {card.title}
                </Typography>
                {cardContent}
            </CardActionArea>
        </Card>
    );
}
