import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MuiTelInput } from "mui-tel-input";

import type { CreateClubSubmitPayload, JoinClubResult } from "@volley/data/dist/types/campaign-club";

import fetchApi from "../../util/fetchApi";
import { STATES } from "../common/data";

import clubReducer, { defaultState as defaultClubState } from "./clubReducer";
import memberReducer, { defaultState as defaultMemberState } from "./memberReducer";

export default function CampaignClubCreate(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as { email?: string } | undefined;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [member, memberDispatch] = React.useReducer(memberReducer, defaultMemberState);
    const [club, clubDispatch] = React.useReducer(clubReducer, defaultClubState);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage("");
        let phone: string | null = member.phone?.trim() ?? "";
        if (phone.length < 10) {
            phone = null;
        }
        const payload: CreateClubSubmitPayload = {
            campaignClub: {
                name: club.name.trim(),
                city: club.city.trim(),
                state: club.state,
            },
            member: {
                firstName: member.firstName.trim(),
                lastName: member.lastName.trim(),
                email: locationState?.email ?? member.email.trim(),
                phone,
                externalId: null,
                referredByMemberId: null,
            },
        };
        try {
            const result = await fetchApi<JoinClubResult>("/api/campaign-clubs", "POST", payload);
            window.localStorage.setItem("volley.campaignClubs.joined", "true");
            navigate(`../t/member_${result.member.trackingId}`, { replace: true });
        } catch (err) {
            setErrorMessage((err as Error).message);
        }
    };

    return (
        <Stack spacing={2} component="form" onSubmit={onSubmit}>
            <Typography variant="h3" component="h1">Add my club</Typography>
            <Typography>Please let us know about you and your club.</Typography>
            <Divider />
            <Typography variant="h4" component="h2">Me</Typography>
            <TextField
                label="First Name"
                value={member.firstName}
                onChange={(e) => memberDispatch({ type: "firstName", value: e.currentTarget.value })}
                required
            />
            <TextField
                label="Last Name"
                value={member.lastName}
                onChange={(e) => memberDispatch({ type: "lastName", value: e.currentTarget.value })}
                required
            />
            {!locationState?.email && (
                <TextField
                    value={member.email}
                    onChange={(e) => memberDispatch({ type: "email", value: e.currentTarget.value })}
                    label="Email"
                />
            )}
            <MuiTelInput
                autoComplete="tel"
                defaultCountry="US"
                onlyCountries={["US"]}
                forceCallingCode
                disableDropdown
                id="phone"
                sx={{ my: 2 }}
                label="Phone Number"
                value={member.phone ?? ""}
                onChange={(value) => memberDispatch({ type: "phone", value })}
            />
            <Divider />
            <Typography variant="h4" component="h2">My Club</Typography>
            <TextField
                label="Club Name"
                value={club.name}
                onChange={(e) => clubDispatch({ type: "name", value: e.currentTarget.value })}
                required
            />
            <TextField
                label="City"
                value={club.city}
                onChange={(e) => clubDispatch({
                    type: "city",
                    value: e.target.value,
                })}
                required
            />
            <FormControl fullWidth>
                <InputLabel id="state-label">State</InputLabel>
                <Select
                    label="State"
                    autoComplete="address-level1"
                    value={club.state}
                    labelId="state-label"
                    id="state"
                    onChange={(e) => clubDispatch({
                        type: "state",
                        value: e.target.value,
                    })}
                    required
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {STATES.map((s) => (
                        <MenuItem value={s.abbreviation} key={s.name}>{s.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {errorMessage && <Typography color="error.main">{errorMessage}</Typography>}
            <Button type="submit" variant="contained">Submit</Button>
        </Stack>
    );
}
