/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import type { FeedbackResponseCreateJson } from "@volley/data/dist/types/feedback";
import { UserSession } from "@volley/shared/coach-models";

import logger, { useLogMountTime } from "../../log";
import { fetchApi } from "../../util";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { usePairingContext } from "../hooks/pairingStatus";

import { DialogType } from "./DialogContext";
import useDialog from "./useDialog";

export const LOCAL_STORAGE_KEY = "SESSION_FEEDBACK_LAST_DATE";
export const RESOLICIT_DAYS = 7;
export const SESSION_LENGTH_MINUTES = 5;

export function shouldSolicitFeedback(session: UserSession | null | undefined, now = Date.now()): boolean {
    try {
        // if we don't have a previous date or the session, we should solicit feedback
        if (!session) {
            logger.warn("[feedback] No session, soliciting feedback");
            return true;
        }

        // session length check, must meet min length
        const sessionStart = new Date(session.startTimestamp);
        const sessionLengthRequired = SESSION_LENGTH_MINUTES * 60 * 1000;

        if (Number.isNaN(sessionStart.getTime())) {
            logger.error("[feedback] Error parsing session start time", session.startTimestamp);
            return false;
        }

        const sessionDuration = now - sessionStart.getTime();
        if (sessionDuration < sessionLengthRequired) {
            logger.info(`[feedback] Session length is ${sessionDuration}, less than ${sessionLengthRequired}`);
            return false;
        }

        // if we don't have a previous date, we should solicit feedback
        const lastDate = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!lastDate) {
            logger.info("[feedback] No last solicited date, soliciting feedback");
            return true;
        }

        // if lastDate is not a number, we should solicit feedback
        // and reset localStorage value
        const lastDateNum = parseInt(lastDate, 10);
        if (Number.isNaN(lastDateNum)) {
            logger.warn("[feedback] Last solicited date is not a number, resetting localStorage");
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            return true;
        }

        // if lastDate is more than 7 days ago, we should solicit feedback
        const resolicitPeriod = RESOLICIT_DAYS * 24 * 60 * 60 * 1000;
        const lastSolicited = now - lastDateNum;
        if (lastSolicited > resolicitPeriod) {
            logger.info(
                `[feedback] Last solicited ${lastSolicited} ago, > than ${resolicitPeriod}, soliciting feedback`,
            );
            return true;
        }

        logger.info(
            `[feedback] Last solicited ${lastSolicited} ago, < than ${resolicitPeriod}, not soliciting feedback`,
        );
    } catch (e) {
        logger.error("[feedback] Error checking if we should solicit feedback", e as Error);
    }

    return false;
}

const labels: { [index: number]: string } = {
    1: "Poor",
    3: "Fair",
    5: "Great",
};

function CustomStar({ value }: { value: number }) {
    return (
        <Box component="div" display="flex" flexDirection="column" alignItems="center">
            <StarIcon fontSize="inherit" />
            <Typography variant="caption" color="initial">{labels[value]}</Typography>
        </Box>
    );
}

function CustomEmptyStar({ value }: { value: number }) {
    return (
        <Box component="div" display="flex" flexDirection="column" alignItems="center">
            <StarBorderIcon fontSize="inherit" />
            <Typography variant="caption" color="initial">{labels[value]}</Typography>
        </Box>
    );
}

type ContainerProps = IconContainerProps & {
    rating: number | null;
    hover: number | null;
    setHover: (value: number | null) => void;
};

function IconContainer(props: ContainerProps) {
    const {
        value,
        rating,
        hover,
        setHover,
        ...other
    } = props;

    const handleMouseOver = () => setHover(value);
    const handleMouseLeave = () => setHover(null);

    /**
     * If the user is hovering over a star, fill to that star
     * Else, if the user has already rated, fill to that star
     * Otherwise, display the empty star.
     */
    const shouldDisplayFilledStar = React.useMemo(() => {
        if (hover !== null) {
            return value <= hover;
        }
        if (rating !== null) {
            return value <= rating;
        }
        return false;
    }, [hover, rating, value]);

    return (
        <span
            onMouseOver={handleMouseOver}
            onFocus={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onBlur={handleMouseLeave}
        >
            {shouldDisplayFilledStar ? (
                <CustomStar value={value} {...other} />
            ) : (
                <CustomEmptyStar value={value} {...other} />
            )}
        </span>
    );
}

interface FeedbackDialogProps {
    postDialog: DialogType;
}
function FeedbackDialog({ postDialog }: FeedbackDialogProps): JSX.Element {
    useLogMountTime("FeedbackDialog");
    const { sessionId } = usePairingContext();
    const { setDialogType } = useDialog();
    const [rating, setRating] = React.useState<number | null>(null);
    const [comment, setComments] = React.useState<string>("");
    const [hover, setHover] = React.useState<number | null>(null);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (sessionId) {
            localStorage.setItem(LOCAL_STORAGE_KEY, Date.now().toString());
        }
    }, [sessionId]);

    const handleSave = async () => {
        setSubmitting(true);
        try {
            if (!sessionId) {
                throw new Error("Session ID is undefined");
            }

            if (!rating) {
                throw new Error("Rating is undefined");
            }

            const feedbackResponse: FeedbackResponseCreateJson = {
                rating,
                comment: comment || null,
                session: { id: sessionId },
            };

            await fetchApi("/api/feedback", "POST", feedbackResponse);
        } catch (e) {
            logger.error("Error submitting feedback", e as Error);
        } finally {
            setSubmitting(false);
            setDialogType(postDialog);
        }
    };

    return (
        <>
            <CloseableDialogTitle
                onClose={() => setDialogType(postDialog)}
            >
                Rate Your Volley Session
            </CloseableDialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="center" component="div">
                    <Rating
                        name="simple-controlled"
                        value={rating}
                        onChange={(event, newValue) => setRating(newValue)}
                        max={5}
                        IconContainerComponent={
                            (props) => (
                                <IconContainer
                                    {...props}
                                    rating={rating}
                                    hover={hover}
                                    setHover={setHover}
                                />
                            )
                        }
                        sx={{ fontSize: "3rem", mb: 4 }}
                    />
                </Box>
                <TextField
                    id="comment"
                    label="Additional Comments"
                    type="text"
                    fullWidth
                    multiline
                    rows={3}
                    value={comment}
                    onChange={(e) => setComments(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    onClick={handleSave}
                    color="primary"
                    disabled={rating === null}
                    loading={submitting}
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function DisconnectFeedbackDialog(): JSX.Element {
    return <FeedbackDialog postDialog="DisconnectCleanupChecklist" />;
}

export function ShutdownFeedbackDialog(): JSX.Element {
    return <FeedbackDialog postDialog="ShutdownCleanupChecklist" />;
}
