import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { SessionWithRelations } from "@volley/data/dist/types/session";
import { TrainerWithRelations } from "@volley/data/dist/types/trainer";
import { CoachStatus } from "@volley/shared/coach-models";

import logger from "../../../log";
import theme from "../../../theme";
import { fetchApi } from "../../../util";
import { FetchError } from "../../../util/fetchApi";
import { retry, cancelRetry, registerRetry } from "../../../util/retry";
import CloseableDialogTitle from "../../common/CloseableDialogTitle";
import { useCurrentUser } from "../../hooks/currentUser";
import { usePairingContext } from "../../hooks/pairingStatus";

import PairFourDigitInput from "./PairFourDigitInput";
import PairPromoCodeEntry from "./PairPromoCodeEntry";
import PairWaiver from "./PairWaiver";

enum UnpairableOpenErrType {
    BOOT = "BOOT",
    MAINTENANCE = "MAINTENANCE",
    OUT_OF_ORDER = "OUT_OF_ORDER",
    SHUTDOWN = "SHUTDOWN",
    UPDATING = "UPDATING",
    NO_SUBSCRIPTION = "NO_SUBSCRIPTION",
    UNKNOWN = "UNKNOWN",
}

const RETRY_KEY = "pairselect";

const PAIRING_FAILURE = "Connection Failed";

const MAINTENANCE_ERROR = `
The trainer is currently in maintenance mode and being updated.
Please leave the trainer powered on, and return it to the storage
area. Thank you.`;

function UnpairableOpenErrMsg({ type }: { type: UnpairableOpenErrType }): JSX.Element {
    switch (type) {
        case UnpairableOpenErrType.SHUTDOWN:
            return (
                <Box component="div">
                    <Typography component="ol" align="left">
                        <li>
                            Hold red Stop button for 15 seconds until trainer lights in the back turn off
                        </li>
                        <li>
                            Turn trainer back on and connect
                        </li>
                    </Typography>
                    <Divider sx={{ m: 2 }} />
                    <Typography sx={{ mx: 2, mb: 2 }}>
                        If you are able, wait until trainer shuts down and the lights go off,
                        then turn back on and connect. This will take up to 2 minutes.
                    </Typography>
                </Box>
            );
        case UnpairableOpenErrType.BOOT:
            return (
                <Typography variant="body2" textAlign="left" gutterBottom sx={{ mb: 2, mt: 0, float: "left" }}>
                    Please confirm the following:
                    <br />
                    1. Are both batteries inserted at the rear of the trainer?
                    <br />
                    2. Make sure the right LED is on and blinking and the left LED is on and solid at the rear
                    of the trainer.
                </Typography>
            );
        case UnpairableOpenErrType.OUT_OF_ORDER:
            return (
                <Typography textAlign="center" gutterBottom mb={2}>
                    Sorry, this trainer is currently out of order.
                </Typography>
            );
        case UnpairableOpenErrType.UPDATING:
            return (
                <>
                    <Typography textAlign="center" gutterBottom mb={4}>
                        The trainer is currently updating. Please wait a moment and try again.
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" my={2}>
                        Do not remove the batteries during this time or shut down the trainer.
                        This could permanently damage the trainer.
                    </Typography>
                </>
            );
        case UnpairableOpenErrType.MAINTENANCE:
            return (
                <Typography variant="body2" textAlign="center" gutterBottom sx={{ mb: 2 }}>
                    {MAINTENANCE_ERROR}
                </Typography>
            );
        case UnpairableOpenErrType.NO_SUBSCRIPTION:
            return (
                <Typography align="center">
                    This location requires an access code to use the trainer.
                    <br />
                    Please enter the code below.
                </Typography>
            );
        default:
            return (
                <Typography variant="body2" textAlign="center" gutterBottom sx={{ mb: 2 }}>
                    Unknown error while pairing to trainer
                </Typography>
            );
    }
}

const isUnauthorized = (e: unknown) => (e as FetchError)?.errorCode === "PAIRING_NO_SUBSCRIPTION";
const isOutOfOrder = (e: unknown) => (e as Error)?.message?.includes("out of order");
const isNotConnectedError = (e: unknown) => (e as Error)?.message?.includes("does not appear to be connected");
const isUnpairableError = (e: unknown): boolean => (e as Error)?.message?.includes("not currently pairable");
const isBootState = (e: unknown): boolean => (e as Error)?.message?.includes("because: BOOT");
const isShutdownState = (e: unknown): boolean => (e as Error)?.message?.includes("because: SHUTDOWN");
const isUpdating = (e: unknown): boolean => (e as Error)?.message?.includes("because: UPDATING");

export default function PairSelect(): JSX.Element {
    const { autoCalibrate, pairingVerified } = usePairingContext();
    const { currentUser, setCurrentUser } = useCurrentUser();
    const [referenceNumber, setReferenceNumber] = React.useState("####");
    const referenceNumberValid = referenceNumber.indexOf("#") === -1;
    const [waiverOpen, setWaiverOpen] = React.useState(false);
    const [loadingOpen, setLoadingOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [unpairableOpen, setUnpairableOpen] = React.useState(false);
    const [unpairableOpenErrType, setUnpairableOpenErrType] = React.useState(UnpairableOpenErrType.MAINTENANCE);
    const [unpairableOpenErrHeaderMsg, setUnpairableOpenErrHeaderMsg] = React.useState(PAIRING_FAILURE);

    React.useEffect(() => {
        logger.info("Resetting retry status on mount.");
        registerRetry(RETRY_KEY);
        return () => {
            logger.info("Cancelling retry on unmount...");
            cancelRetry(RETRY_KEY);
        };
    }, []);

    const goToTrainer = React.useCallback(async (session: SessionWithRelations) => {
        const trainer = await fetchApi<TrainerWithRelations>(`/api/trainers/${session.sessionTrainers[0].trainerId}`);
        try {
            await retry(
                async () => {
                    const status = await fetchApi<CoachStatus>(
                        `/trainer/${trainer.clientId}/api/status?forceRefresh=true`,
                    );
                    if (status?.session?.id !== session.id) {
                        throw new Error("Session on trainer doesn't match");
                    }
                },
                RETRY_KEY,
                1000,
                5,
            );
            pairingVerified(trainer.clientId, session.id);
        } catch (err: unknown) {
            setLoadingOpen(false);
            setErrorOpen(true);
        }
    }, [pairingVerified]);

    const pair = React.useCallback(async () => {
        setErrorOpen(false);
        setLoadingOpen(true);
        try {
            const session = await retry(
                async () => {
                    const result = await fetchApi<SessionWithRelations>(
                        "/api/sessions/pair",
                        "POST",
                        { referenceNumber, autoCalibrate },
                    );
                    return result;
                },
                RETRY_KEY,
                1000,
                15,
                (e: unknown) => isUnpairableError(e) || isOutOfOrder(e) || isUnauthorized(e),
            );
            return session;
            // A hook in the session provider makes the user go into the trainer context when there's a session
        } catch (err: unknown) {
            setLoadingOpen(false);

            if (isOutOfOrder(err)) {
                setUnpairableOpenErrHeaderMsg("Out of Order");
                setUnpairableOpenErrType(UnpairableOpenErrType.OUT_OF_ORDER);
                setUnpairableOpen(true);
                logger.warn("User tried to pair with out of order trainer.");
                return null;
            }

            if (isUnauthorized(err)) {
                const fetchError = err as FetchError;
                if (fetchError.errorCode === "PAIRING_NO_SUBSCRIPTION") {
                    setUnpairableOpenErrHeaderMsg("Access Code Needed");
                    setUnpairableOpenErrType(UnpairableOpenErrType.NO_SUBSCRIPTION);
                } else {
                    setUnpairableOpenErrHeaderMsg("Unauthorized");
                    setUnpairableOpenErrType(UnpairableOpenErrType.UNKNOWN);
                }
                setUnpairableOpen(true);
                logger.warn(`User tried to pair with unauthorized trainer: ${fetchError.errorCode ?? "?"}`);
                return null;
            }

            if (isUnpairableError(err)) {
                if (isBootState(err)) {
                    // Boot error will help user check their battery, but it's also a catch all for
                    // many other problems. Further error message refinement would be needed later on
                    // throughout the various services such as motion and coach.
                    setUnpairableOpenErrHeaderMsg("Check Batteries & Hardware");
                    setUnpairableOpenErrType(UnpairableOpenErrType.BOOT);
                } else if (isShutdownState(err)) {
                    setUnpairableOpenErrHeaderMsg("Trainer in Shutdown Process");
                    setUnpairableOpenErrType(UnpairableOpenErrType.SHUTDOWN);
                } else if (isUpdating(err)) {
                    setUnpairableOpenErrHeaderMsg("Trainer is Updating");
                    setUnpairableOpenErrType(UnpairableOpenErrType.UPDATING);
                } else {
                    setUnpairableOpenErrHeaderMsg(PAIRING_FAILURE);
                    setUnpairableOpenErrType(UnpairableOpenErrType.MAINTENANCE);
                }
                setUnpairableOpen(true);
                logger.warn("User tried to pair when trainer was unpairable.");
                return null;
            }

            if (isNotConnectedError(err)) {
                setUnpairableOpenErrHeaderMsg("Trainer Not Connected");
                setUnpairableOpenErrType(UnpairableOpenErrType.BOOT);
                setUnpairableOpen(true);
                logger.warn("User tried to pair before trainer was connected to bridge.");
                return null;
            }

            logger.warn("Unable to pair with trainer after 15 tries.", {
                error: (err as Error).message,
            });
            setErrorOpen(true);
            return null;
        }
    }, [referenceNumber, autoCalibrate]);

    const onSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (currentUser && !currentUser.termsOfServiceAcceptedAt) {
            setWaiverOpen(true);
            return;
        }

        const session = await pair();
        if (session) {
            await goToTrainer(session);
        }
    }, [pair, currentUser, goToTrainer]);

    const onWaiverClose = React.useCallback(async (accepted: boolean) => {
        setWaiverOpen(false);
        if (accepted && currentUser) {
            setCurrentUser({ ...currentUser, termsOfServiceAcceptedAt: new Date() });
            const session = await pair();
            if (session) {
                await goToTrainer(session);
            }
        }
    }, [currentUser, setCurrentUser, pair, goToTrainer]);

    const onPromoCodeEntryDone = React.useCallback(async () => {
        setUnpairableOpen(false);
        const session = await pair();
        if (session) await goToTrainer(session);
    }, [pair, goToTrainer]);

    const onLoadingClosed = React.useCallback(() => {
        logger.info("Cancelling retry on dialog close");
        setLoadingOpen(false);
        cancelRetry(RETRY_KEY);
    }, []);

    return (
        <Container maxWidth="xs" component="form" onSubmit={onSubmit}>
            <Typography variant="h3" component="h2" sx={{ mt: 2, fontSize: "17pt" }} gutterBottom color="primary.main">
                Enter Trainer ID to Begin
            </Typography>
            <Divider sx={{ my: 2, borderColor: theme.palette.primary.main }} />
            <List component="ol" sx={{ listStyle: "outside", listStyleType: "decimal", px: 2 }}>
                <ListItem sx={{ display: "list-item", pl: 0.5 }}>
                    <ListItemText>
                        If the trainer is off, power on by pressing the green
                        button, located at the rear of the trainer.
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item", pl: 0.5 }}>
                    <ListItemText>
                        Enter the 4 digit number located on the side of the trainer.
                    </ListItemText>
                </ListItem>
            </List>
            <PairFourDigitInput value={referenceNumber} onChange={setReferenceNumber} />
            <Box component="div" sx={{ my: 2 }}>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    color="secondary"
                    disabled={!referenceNumberValid}
                >
                    Connect
                </Button>
            </Box>

            {/* Waiver Dialog */}
            <PairWaiver open={waiverOpen} onClose={onWaiverClose} />

            {/* Loading Dialog */}
            <Dialog open={loadingOpen} onClose={() => onLoadingClosed()}>
                <CloseableDialogTitle onClose={() => onLoadingClosed()}>
                    <Typography variant="h4" component="p" color="primary">
                        Connecting to the Trainer
                    </Typography>
                </CloseableDialogTitle>
                <Divider sx={{ mx: 2 }} />
                <DialogContent>
                    <Typography align="center" variant="body2" gutterBottom>
                        Please wait a moment while we connect with the trainer.
                    </Typography>
                    <Box component="div" display="flex" justifyContent="center" my={2}>
                        <CircularProgress variant="indeterminate" color="info" size={60} />
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Unpairable Dialog */}
            <Dialog open={unpairableOpen} onClose={() => setUnpairableOpen(false)}>
                <CloseableDialogTitle onClose={() => setUnpairableOpen(false)}>
                    <Typography variant="h4" component="p" color="primary">
                        {unpairableOpenErrHeaderMsg}
                    </Typography>
                </CloseableDialogTitle>
                <Divider sx={{ mx: 2 }} />
                <DialogContent>
                    <UnpairableOpenErrMsg type={unpairableOpenErrType} />
                    {unpairableOpenErrType === UnpairableOpenErrType.NO_SUBSCRIPTION ? (
                        <PairPromoCodeEntry onDone={onPromoCodeEntryDone} />
                    ) : (
                        <Button
                            type="button"
                            variant="contained"
                            size="large"
                            fullWidth
                            color="secondary"
                            onClick={() => setUnpairableOpen(false)}
                            sx={{ mt: 2 }}
                        >
                            OK
                        </Button>
                    )}
                </DialogContent>
            </Dialog>

            {/* Error Dialog */}
            <Dialog open={errorOpen} onClose={() => setErrorOpen(false)}>
                <CloseableDialogTitle onClose={() => setErrorOpen(false)}>
                    <Typography variant="h4" component="p" color="primary">
                        Connection Failed
                    </Typography>
                </CloseableDialogTitle>
                <Divider sx={{ mx: 2 }} />
                <DialogContent>
                    <Typography variant="body2" gutterBottom>
                        Confirm the following:
                    </Typography>
                    <List component="ol" sx={{ listStyle: "outside", listStyleType: "decimal", px: 2 }}>
                        <ListItem sx={{ display: "list-item", pl: 0.5 }}>
                            <Typography variant="body2">
                                Are both batteries fully inserted?
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0.5 }}>
                            <Typography variant="body2">
                                Is the trainer turned on? The trainer’s light(s) above the batteries should be on.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0.5 }}>
                            <Typography variant="body2">
                                Is the four digit connect code correct?
                            </Typography>
                        </ListItem>
                    </List>
                    <Typography variant="body2">
                        If the above is confirmed, then you are most likely experiencing
                        a temporary network connection issue.
                    </Typography>
                    <Box component="form">
                        <PairFourDigitInput value={referenceNumber} onChange={setReferenceNumber} />
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            fullWidth
                            color="secondary"
                            disabled={!referenceNumberValid}
                        >
                            Try Again
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Container>
    );
}
