import * as React from "react";
import {
    Routes, Route,
} from "react-router-dom";

import Pair from "./Pair";

export default function Sessions(): JSX.Element {
    return (
        <Routes>
            <Route path="pair/*" element={<Pair />} />
        </Routes>
    );
}
