import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import VideocamIcon from "@mui/icons-material/Videocam";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";

import { pairedFetchApi, logFetchError } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";
import useFreshchat from "../hooks/useFreshchat";

import useDialog from "./useDialog";

interface BoldTypographyProps extends TypographyProps {
    children: React.ReactNode;
}
function BoldTypography({ children }: BoldTypographyProps): JSX.Element {
    return (
        <Typography variant="body1" fontSize="1.25rem" fontWeight="bold" mb={2}>
            {children}
        </Typography>
    );
}

export interface BallJamPreambleProps {
    onClear: () => void;
    onRetry: () => void;
}
export default function BallJamPreamble({ onClear, onRetry }: BallJamPreambleProps): JSX.Element {
    const { setDialogType } = useDialog();
    const { hasFault, status } = useStatus();
    const freshchat = useFreshchat();
    const [retrying, setRetrying] = React.useState(false);
    const [loweringPower, setLoweringPower] = React.useState(false);

    React.useEffect(() => {
        if (retrying && !hasFault) {
            onRetry();
        }
    }, [retrying, hasFault, onRetry]);

    const onClearRetry = React.useCallback(async () => {
        setRetrying(true);
        try {
            await pairedFetchApi(status?.clientId, "/api/clear-fault", "POST");
        } catch (e) {
            logFetchError(e, "Failed to clear fault from BalljamPreamble");
        }
    }, [status?.clientId]);

    const onClearLowerPower = React.useCallback(async () => {
        setLoweringPower(true);
        try {
            await pairedFetchApi(status?.clientId, "/api/motors-off", "POST");
        } catch (e) {
            logFetchError(e, "Failed to soft-stop from BalljamPreamble");
        }
    }, [status?.clientId]);

    React.useEffect(() => {
        if (loweringPower
            && status?.trainer.powerState === "L2"
            && (status?.trainer.state === "IDLE" || status?.trainer.state === "UNCALIBRATED")
        ) {
            onClear();
        }
    }, [loweringPower, onClear, status?.trainer.powerState, status?.trainer.state]);

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={() => setDialogType("Device")}>
                Ball Jam
            </CloseableDialogTitle>
            <DialogContent dividers>
                <BoldTypography>
                    Wet balls are the likely cause of ball jams. Do not use wet balls.
                </BoldTypography>
            </DialogContent>
            <DialogActions>
                <Stack width="100%">
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        fullWidth
                        loading={retrying}
                        onClick={onClearRetry}
                        sx={{ mb: 2 }}
                    >
                        Retry
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        color="info"
                        fullWidth
                        onClick={onClearLowerPower}
                        loading={loweringPower}
                        sx={{ mb: 2 }}
                    >
                        Clear Jam Manually
                    </LoadingButton>
                    <Stack mt={1}>
                        <Button
                            onClick={() => {
                                window.open("https://www.youtube.com/watch?v=8Yp8J8tvWMs", "_blank");
                            }}
                            startIcon={<VideocamIcon />}
                        >
                            Watch how
                        </Button>
                        <Button
                            onClick={() => { freshchat.open(); }}
                            startIcon={<HelpIcon />}
                        >
                            Need help? Chat with support.
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </>
    );
}
