import * as React from "react";

import type { Position, VisionLocalizationStatus, Coordinate } from "@volley/shared/vision-models";

import logger from "../../log";
import { pairedFetchApi, logFetchError } from "../../util/fetchApi";
import { PositionLike } from "../../util/position-types";

import { useStatus } from "./status";

export type PositionWithHeight = PositionLike & { heightIn: number };

export type LocalizationStatus =
    | "idle"
    | "requested"
    | "pending"
    | "cancelling"
    | "localizing"
    | "unavailable"
    | "error";
export type PositionProximity = "Good" | "Improve" | "Unsure" | "Unavailable" | "Not Ready";
interface PositionStateInternal {
    requestedAt: number | undefined;
    error: string | undefined;
    position: Position | undefined;
    improve: boolean,
    isVisionStarting: boolean,
    isVisionFaulted: boolean,
    localizationStatus: LocalizationStatus;
    delta: Coordinate | undefined;
}

export interface PositionState extends PositionStateInternal {
    isLocalizing: boolean,
    isCanceled: boolean,
    updatePosition: (hintPosition?: PositionWithHeight, oneShot?: boolean) => void;
    cancel: () => void;
}

const defaultPositionStateInternal: PositionStateInternal = {
    requestedAt: undefined,
    error: undefined,
    position: undefined,
    improve: false,
    isVisionStarting: true,
    isVisionFaulted: false,
    localizationStatus: "idle",
    delta: undefined,
};

const defaultLocalizationState: PositionState = {
    ...defaultPositionStateInternal,
    isLocalizing: false,
    isCanceled: false,
    updatePosition: () => { },
    cancel: () => { },
};

export const LocalizationContext = React.createContext<PositionState>(defaultLocalizationState);

type PositionStateChange =
    | { type: "set-error", value: string }
    | { type: "set-faulted", value: boolean }
    | { type: "request-cancel" }
    | { type: "cancel-confirmed" }
    | { type: "request-localization" }
    | { type: "vision-status", value: boolean }
    | { type: "localization-unavailable" }
    | { type: "localization-confirmed" }
    | { type: "localization-confirm-fail", value: string }
    | { type: "localization-in-progress" }
    | { type: "completed", value: VisionLocalizationStatus };

function positionReducer(
    state: PositionStateInternal,
    action: PositionStateChange,
): PositionStateInternal {
    switch (action.type) {
        case "vision-status": return {
            ...state,
            isVisionStarting: action.value,
        };
        case "set-error": return {
            ...state,
            error: action.value,
        };
        case "set-faulted": return {
            ...state,
            isVisionFaulted: action.value,
            isVisionStarting: !action.value,
        };
        case "request-cancel": return {
            ...state,
            localizationStatus: "cancelling",
            error: undefined,
            improve: false,
            position: undefined,
            requestedAt: performance.now(),
        };
        case "cancel-confirmed": return {
            ...defaultPositionStateInternal,
            isVisionStarting: state.isVisionStarting,
        };
        case "request-localization": return {
            ...defaultPositionStateInternal,
            isVisionStarting: state.isVisionStarting,
            localizationStatus: "pending",
            requestedAt: performance.now(),
        };
        case "localization-unavailable": return {
            ...defaultPositionStateInternal,
            isVisionStarting: state.isVisionStarting,
            localizationStatus: "unavailable",
        };
        case "localization-confirmed": return {
            ...state,
            localizationStatus: "localizing",
            requestedAt: undefined,
        };
        case "localization-in-progress": return {
            ...defaultLocalizationState,
            isVisionStarting: state.isVisionStarting,
            localizationStatus: "localizing",
        };
        case "localization-confirm-fail": return {
            ...state,
            localizationStatus: "error",
            error: action.value,
            requestedAt: undefined,
        };
        case "completed": return {
            ...defaultPositionStateInternal,
            isVisionStarting: state.isVisionStarting,
            position: action.value.position ?? undefined,
            error: action.value.position?.error ?? undefined,
            improve: action.value.improve,
            delta: action.value.delta,
        };
        default: return state;
    }
}

export const LocalizationProvider = React.memo(({ children }: React.PropsWithChildren) => {
    const { status } = useStatus();
    const [stateInternal, dispatch] = React.useReducer(positionReducer, defaultPositionStateInternal);

    React.useEffect(() => {
        logger.info(`[usePosition] Internal State Changed: ${JSON.stringify(stateInternal)}`);
    }, [stateInternal]);

    React.useEffect(() => {
        if (!status?.localization) {
            return;
        }
        const updatedVisionStarting = status?.vision?.visionSystemStarting ?? false;
        if (stateInternal.isVisionStarting !== updatedVisionStarting) {
            if (status?.vision?.serviceState !== "Fault") {
                logger.info(`[usePosition] - Setting vision status to '${updatedVisionStarting ? "Starting" : "Up"}'`);
                dispatch({ type: "vision-status", value: updatedVisionStarting });
            }
        }

        if (status?.vision?.serviceState === "Fault" && !stateInternal.isVisionFaulted) {
            logger.info("[usePosition] - Vision reporting a fault.");
            dispatch({ type: "set-faulted", value: true });
            return;
        }

        if (stateInternal.isVisionFaulted) {
            if (status?.vision?.serviceState === "Running" && status?.vision?.visionSystemStarting !== true) {
                logger.info("[usePosition] - Vision no longer faulted");
                dispatch({ type: "set-faulted", value: false });
                return;
            }
        }

        const localizing = (status?.localization?.isActive ?? false);
        // We asked for a localization job and coach has now confirmed it is running
        if (localizing && stateInternal.localizationStatus === "pending") {
            logger.info("[usePosition] - Localization was Pending - Confirmed by Coach Status");
            dispatch({ type: "localization-confirmed" });
            return;
        }

        // This can happen if localization is in progress and the user refreshes/navigates
        if (localizing && stateInternal.localizationStatus === "idle") {
            logger.info("[usePosition] - Localization  was idle, but is active from state - resetting internal state");
            dispatch({ type: "localization-in-progress" });
            return;
        }

        // We were tracking a localization task, but coach's status indicates it is no longer active
        if (!localizing && stateInternal.localizationStatus === "localizing") {
            logger.info("[usePosition] - Localization was active, status indicates it is no longer active");
            dispatch({ type: "completed", value: status?.localization });
            return;
        }

        // We asked to cancel the running localization job and coach is validating our request
        if (!localizing && stateInternal.localizationStatus === "cancelling") {
            logger.info("[usePosition] - Cancellation confirmed, status indicates it is no longer active");
            dispatch({ type: "cancel-confirmed" });
            return;
        }

        // Clear started startedLocalizing if we have gotten a sufficiently up-to-date status
        if (stateInternal.requestedAt
            && performance.now() > stateInternal.requestedAt + 10_000) {
            dispatch({ type: "localization-confirm-fail", value: "Status not confirmed after 5 seconds, aborting" });
        }
    }, [status?.vision, status?.localization, stateInternal]);

    // eslint-disable-next-line max-len
    const fetchPosition = React.useCallback(async (hintPosition?: PositionWithHeight, oneShot?: boolean) => {
        if (stateInternal.isVisionStarting) {
            logger.info("[usePosition] - returning early from 'fetchPosition' because vision is not yet ready");
            dispatch({ type: "set-error", value: "NOT_READY" });
            return;
        }

        if (stateInternal.isVisionFaulted) {
            logger.info("[usePosition] - returning early from 'fetchPosition' because vision is not yet ready");
            dispatch({ type: "set-error", value: "FAULTED" });
            return;
        }

        if (stateInternal.localizationStatus !== "idle") {
            logger.info(`[usePosition] - returning early from 'fetchPosition': ${stateInternal.localizationStatus}`);
            return;
        }

        dispatch({ type: "request-localization" });

        try {
            await pairedFetchApi(status?.clientId, "/api/position", "POST", { oneshot: oneShot, hint: hintPosition });
        } catch (e) {
            logFetchError(e, "[usePosition] - Request to start localizing failed");
            dispatch({ type: "localization-confirm-fail", value: (e as Error).message });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status?.clientId, stateInternal]);

    // SoftStop
    const cancel = React.useCallback(() => {
        logger.info("[usePosition] - user requested localization cancellation");
        dispatch({ type: "request-cancel" });
        pairedFetchApi(status?.clientId, "/api/cancel-position", "POST")
            .catch((e) => logFetchError(e))
            .finally(() => {
                pairedFetchApi(status?.clientId, "/api/motors-stop", "POST")
                    .catch((stopError) => {
                        logFetchError(stopError);
                    });
            });
    }, [status?.clientId]);

    const state: PositionState = React.useMemo(() => ({
        ...stateInternal,
        isCanceled: stateInternal.localizationStatus === "cancelling",
        isLocalizing: stateInternal.localizationStatus !== "idle",
        cancel,
        updatePosition: fetchPosition,
    }), [cancel, stateInternal, fetchPosition]);

    return (
        <LocalizationContext.Provider value={status === null ? defaultLocalizationState : state}>
            {children}
        </LocalizationContext.Provider>
    );
});

export default function usePosition(): PositionState {
    return React.useContext(LocalizationContext);
}
