/* eslint-disable max-classes-per-file */
import * as React from "react";
import {
    Navigate, Routes, Route,
} from "react-router-dom";

export default function Pair(): JSX.Element {
    return (
        <Routes>
            <Route path="" element={<Navigate replace to="/" />} />
            <Route path="select" element={<Navigate replace to="/" />} />
        </Routes>
    );
}
