import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MuiTelInput } from "mui-tel-input";

import type { JoinClubResult, JoinClubSubmitPayload } from "@volley/data/dist/types/campaign-club";

import fetchApi from "../../util/fetchApi";

import memberReducer, { defaultState as defaultMemberState } from "./memberReducer";

type LocationState = Partial<{
    email: string;
    campaignClubId: number;
    campaignClubName: string;
    referredByMemberTrackingId: string;
}>;

export default function CampaignClubJoin(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as LocationState | null;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [member, memberDispatch] = React.useReducer(memberReducer, defaultMemberState);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage("");
        if (!locationState?.campaignClubId) return;
        let phone: string | null = member.phone?.trim() ?? "";
        if (phone.length < 10) {
            phone = null;
        }
        const payload: JoinClubSubmitPayload = {
            referredByMemberTrackingId: locationState.referredByMemberTrackingId ?? null,
            member: {
                firstName: member.firstName.trim(),
                lastName: member.lastName.trim(),
                email: locationState.email ?? member.email.trim(),
                phone,
                campaignClubId: locationState.campaignClubId,
                externalId: null,
                referredByMemberId: null,
            },
        };
        try {
            const result = await fetchApi<JoinClubResult>("/api/campaign-clubs/join", "POST", payload);
            window.localStorage.setItem("volley.campaignClubs.joined", "true");
            navigate(`../t/member_${result.member.trackingId}`, { replace: true });
        } catch (err) {
            setErrorMessage((err as Error).message);
        }
    };

    return (
        <Stack spacing={2} component="form" onSubmit={onSubmit}>
            <Typography variant="h3" component="h1" color="primary">
                {`Join ${locationState?.campaignClubName ?? "my club"}`}
            </Typography>
            <Typography>Please let us know a little about you.</Typography>
            <TextField
                label="First Name"
                value={member.firstName}
                onChange={(e) => memberDispatch({ type: "firstName", value: e.currentTarget.value })}
                required
            />
            <TextField
                label="Last Name"
                value={member.lastName}
                onChange={(e) => memberDispatch({ type: "lastName", value: e.currentTarget.value })}
                required
            />
            <MuiTelInput
                autoComplete="tel"
                defaultCountry="US"
                onlyCountries={["US"]}
                forceCallingCode
                disableDropdown
                id="phone"
                sx={{ my: 2 }}
                label="Phone Number"
                value={member.phone ?? ""}
                onChange={(value) => memberDispatch({ type: "phone", value })}
            />
            {errorMessage && <Typography color="error.main">{errorMessage}</Typography>}
            <Button type="submit" variant="contained">Submit</Button>
        </Stack>
    );
}
