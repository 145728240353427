import * as React from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import SupportLink from "../common/SupportLink";

import useDialog from "./useDialog";

interface ClearObstructionDialogProps {
    onCleared: () => void;
}
export default function ClearObstructionDialog({ onCleared }: ClearObstructionDialogProps): JSX.Element {
    const { setIgnoreProximity, setDialogType } = useDialog();

    const onClickIgnore = React.useCallback(() => {
        setIgnoreProximity(true);
        setDialogType(null);
    }, [setDialogType, setIgnoreProximity]);

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Clear Obstructions
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please check there isn&apos;t anything directly in front of the trainer.
                    If there is, please secure it off the court.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack width="100%" spacing={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={onCleared}
                    >
                        Done
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={onClickIgnore}
                    >
                        Ignore
                    </Button>
                </Stack>
            </DialogActions>
            <SupportLink preText="Having Trouble?">
                Click here.
            </SupportLink>
        </>
    );
}
