import type { CampaignClubMemberCreatePayload } from "@volley/data/dist/types/campaign-club";

export const defaultState: CampaignClubMemberCreatePayload = {
    firstName: "",
    lastName: "",
    email: "",
    phone: null,
    externalId: "",
    campaignClubId: -1,
    referredByMemberId: null,
};

type Action<K extends keyof CampaignClubMemberCreatePayload> = {
    type: K;
    value: CampaignClubMemberCreatePayload[K];
};

export default function memberReducer<K extends keyof CampaignClubMemberCreatePayload>(
    state: CampaignClubMemberCreatePayload,
    action: Action<K>,
): CampaignClubMemberCreatePayload {
    return { ...state, [action.type]: action.value };
}
