/* eslint-disable max-len */
import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { fetchApi } from "../../../util";
import { VolleyButton } from "../../common/buttons";
import { useCurrentUser } from "../../hooks/currentUser";

interface Props {
    open: boolean;
    onClose: (accepted: boolean) => void;
}

export default function PairWaiver({ open, onClose }: Props): JSX.Element {
    const { currentUser } = useCurrentUser();
    const formRef = React.useRef<HTMLFormElement | null>(null);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [showVScrollIndicator, setShowVScrollIndicator] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [errorMessage, setErrorMessage] = React.useState("");

    const onSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!currentUser) {
            return;
        }

        if (!termsAccepted) {
            setErrorMessage("Please accept the liability waiver by checking the box.");
            return;
        }

        setErrorMessage("");
        await fetchApi(`/api/users/${currentUser.id}/terms-of-service`, "PUT");

        onClose(true);
    }, [currentUser, termsAccepted, onClose]);

    const ticking = React.useRef(false);
    const onScroll = () => {
        if (ticking.current) return;
        window.requestAnimationFrame(() => {
            if (!formRef.current) return;
            const { top, bottom } = formRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            if (top < windowHeight && bottom >= 0) {
                setShowVScrollIndicator(false);
            } else {
                setShowVScrollIndicator(true);
            }
            ticking.current = false;
        });
        ticking.current = true;
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => onClose(false)}
            PaperProps={{ onScroll }}
        >
            <Grid container direction="row" alignItems="center" sx={{ p: 3 }}>
                <Grid item xs={10}>
                    <Typography variant="h3" component="h1">
                        Liability Waiver
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "right" }}>
                    <IconButton onClick={() => onClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Typography sx={{ p: 2 }}>
                Please carefully read the terms of use and liability waiver below.
                You will have to agree to these terms to use the training machine.
            </Typography>
            <Box component="div" p={2}>
                <Typography variant="h5" component="h2" gutterBottom>
                    RELEASE OF LIABILITY AND ASSUMPTION OF RISK
                </Typography>
                <Typography gutterBottom>
                    I desire to use Volley (the “Trainer”), which is an automated tennis ball trainer/throwing machine (the “Activity”) provided by Volley LLC, a Pennsylvania Limited Liability Corporation (the “Company”) at 1811 Rohrerstown Rd, Lancaster PA 17601. In consideration of being permitted by the Company to engage in the Activity/the intangible value that I will gain by participating in the Activity, and in recognition of the Company&apos;s reliance hereon, I agree to all of the terms and conditions set forth below (this “Release”).
                </Typography>
                <Typography gutterBottom>
                    1. I am aware and understand that the Activity is a potentially dangerous activity and involves the risk of serious injury, disability, death, and property damage, including, but not limited to, property damage or injury resulting from misaimed or “wild” balls thrown by the Trainer, balls that ricochet off a paddle or part of the court, balls on the ground that can cause a slip, trip or fall, Trainer tipping over on a person or property, injury to or loss of limbs (including fingers or hair) caught in Trainer moving parts, balls falling on persons or property while loading into the ball bin, and possible electric shock.  I am also aware of the highly contagious nature of bacterial and viral diseases, including the 2019 novel coronavirus disease (COVID-19) collectively, the “Disease”) and the risk that I may be exposed to or contract the Disease by engaging in the Activity, which may result in serious illness, personal injury, disability, death, and/or property damage. I acknowledge that these risks may result from or be compounded by the actions, omissions, or negligence of Company employees or others, including negligent emergency response or rescue operations of the Company. I understand that while the Company has implemented measures to reduce the risk of injury from the Activity and the spread of the Disease, the Company cannot guarantee that I will not be injured or become infected with the Disease or other infectious diseases due to my participation in the Activity and that engaging in the Activity may increase my risk of contracting the Disease. NOTWITHSTANDING THESE RISKS, I ACKNOWLEDGE THAT I AM VOLUNTARILY PARTICIPATING IN THE ACTIVITY WITH KNOWLEDGE OF THE DANGERS INVOLVED. I HEREBY AGREE TO ACCEPT AND ASSUME ALL RISKS OF INJURY, ILLNESS, DISABILITY, DEATH, AND/OR PROPERTY DAMAGE ARISING FROM MY ENGAGING IN THE ACTIVITY, WHETHER CAUSED BY THE ORDINARY NEGLIGENCE OF THE COMPANY OR OTHERWISE.
                </Typography>
                <Typography gutterBottom>
                    2. I hereby expressly waive and release any and all claims, now known or hereafter known, against the Company and its affiliates and their respect officers, directors, manager(s), employees, agents, shareholders/members, successors, and assigns (collectively, “Releasees”), on account of injury, illness, disability, death, or property damage arising out of or attributable to the Activity, whether arising out of the ordinary negligence of the Company or any Releasees or otherwise. I covenant not to make or bring any such claim against the Company or any other Releasee, and forever release and discharge the Company and all other Releasees from liability under such claims. This waiver and release does not extend to claims for gross negligence, willful misconduct, or any other liabilities that Pennsylvania law does not permit to be released by agreement.
                </Typography>
                <Typography gutterBottom>
                    3. I confirm that I am: (a) in good health, in proper physical condition, and do not have any medical or other conditions that would impair my ability to participate in the Activity; and (b) not experiencing symptoms of the Disease (such as cough, shortness of breath, or fever), do not have a confirmed or suspected case of the Disease, and have not come in contact in the last 14 days with a person who has been confirmed to have or suspected of having the Disease. I will comply with all federal, state, and local laws, orders, directives, and guidelines related to the Activity and the Disease while participating in the Activity, including, without limitation, requirements related to hand sanitation, social distancing, and use of face coverings and safety equipment. I will also follow all instructions, recommendations, and cautions of the Company at all times during the Activity. If at any time I believe conditions to be unsafe, that I am no longer in proper physical condition to participate in the Activity, or I begin experiencing symptoms of the Disease, I will immediately discontinue further participation in the Activity.
                </Typography>
                <Typography gutterBottom>
                    4. I shall defend, indemnify, and hold harmless the Company and all other Releasees against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorney fees, fees, the costs of enforcing any right to indemnification under this Release, and the cost of pursuing any insurance providers, incurred by/awarded against the Company or any other Releasees, arising out or resulting from my participation in the Activity, including injury to third parties, including third parties that I invite to participate in or observe the Activity, which occurs during the Activity, and including any claim related to my own negligence or the ordinary negligence of the Company.
                </Typography>
                <Typography gutterBottom>
                    5. I hereby consent to receive medical treatment deemed necessary if I am injured or require medical attention during my participation in the Activity. I understand and agree that I am solely responsible for all costs related to such medical treatment and any related medical transportation and/or evacuation. I hereby release, forever discharge, and hold harmless the Company from any claim based on such treatment or other medical services.
                </Typography>
                <Typography gutterBottom>
                    6. Any dispute, controversy or claim arising out of or relating in any way to this Agreement or the Activity including, without limitation, any dispute concerning the construction, validity, interpretation, enforceability or breach of the Agreement, shall be exclusively resolved by binding arbitration upon a Party’s submission of the dispute to arbitration. The demand for arbitration shall be made within a reasonable time after the claim, dispute or other matter in question has arisen, and in no event shall it be made after two years from when the aggrieved party knew or should have known of the controversy, claim, dispute or breach.  The arbitration shall be conducted by one arbitrators. If the Parties are not able to agree upon the selection of an arbitrator, within twenty days of commencement of an arbitration proceeding by service of a demand for arbitration, the arbitrator shall be selected.  The arbitration shall be conducted in accordance with the then existing Commercial Rules of the American Arbitration Association and shall be held in Lancaster, Pa.
                    <br />
                    It is the intent of the parties that, barring extraordinary circumstances, arbitration proceedings will be concluded within one hundred and twenty days from the date the arbitrators are appointed. The arbitrators may extend this time limit in the interests of justice. Failure to adhere to this time limit shall not constitute a basis for challenging the award. The Parties shall not be entitled to discovery in the arbitration.  The Parties shall exchange a copy of all exhibits for the arbitration hearing and shall identify each witness who will testify at the arbitration, with a summary of the anticipated testimony of such witness [ten] days before the arbitration hearing.
                    <br />
                    Each party shall pay its own proportionate share of arbitrator fees and expenses and the arbitration fees and expenses of the American Arbitration Association. The arbitrator[s] shall be entitled to award the foregoing arbitration and administrative fees and expenses as damages in his/her discretion.
                </Typography>
                <Typography gutterBottom>
                    7. This Release constitutes the sole and entire agreement of the Company and me with respect to the subject matter contained herein and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter. If any term or provision of this Release is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Release or invalidate or render unenforceable such term or provision in any other jurisdiction. This Release is binding on and shall inure to the benefit of the Company and me and our respective successors and assigns. All matters arising out of or relating to this Release shall be governed by and construed in accordance with the internal laws of the State of Pennsylvania without giving effect to any choice or conflict of law provision or rule (whether of the State of Pennsylvania or any other jurisdiction). Any claim or cause of action arising under this Release may be brought only in the federal and state courts located in Lancaster County, Pennsylvania and I hereby consent to the exclusive jurisdiction of such courts.
                </Typography>
                <Typography gutterBottom>
                    I ACKNOWLEDGE THAT I HAVE READ AND UNDERSTOOD ALL OF THE TERMS OF THIS RELEASE AND THAT I AM VOLUNTARILY GIVING UP SUBSTANTIAL LEGAL RIGHTS, INCLUDING THE RIGHT TO SUE THE COMPANY.
                </Typography>
            </Box>
            <Stack spacing={2} component="form" onSubmit={onSubmit} ref={formRef} sx={{ mx: 2, mb: 4 }}>
                <Typography sx={{ mt: 2 }}>
                    THIS IS A RELEASE OF LIABILITY - DO NOT SIGN IT UNLESS YOU AGREE TO BE BOUND BY ITS TERMS.
                </Typography>
                {!!errorMessage && <Typography color="error.main">{errorMessage}</Typography>}
                <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <FormControlLabel
                        htmlFor="termsAccepted"
                        label="Tap to agree"
                        control={(
                            <Checkbox
                                id="termsAccepted"
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                            />
                        )}
                    />
                </Stack>
                <VolleyButton type="submit">
                    Continue
                </VolleyButton>
            </Stack>
            <Fab
                variant="extended"
                aria-label="keep scrolling down"
                sx={{
                    position: "fixed",
                    display: showVScrollIndicator ? "flex" : "none",
                    bottom: fullScreen ? 10 : 40,
                    left: "50%",
                    right: "50%",
                    transform: "translateX(-50%)",
                    opacity: 0.9,
                    width: 300,
                    cursor: "default",
                }}
            >
                <ExpandCircleDownIcon sx={{ mr: 1 }} />
                Scroll down to accept
            </Fab>
        </Dialog>
    );
}
