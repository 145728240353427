/* eslint-disable max-len */
import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EmptyBallBin(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <path d="M47.1,43.6L5.4,1.7L0.9,6.1l12,12h-0.2L17,43c0.2,1.4,1.4,2.4,2.9,2.4h11.5c1.4,0,2.6-1,2.9-2.4l0.5-2.9l7.8,7.9L47.1,43.6 z" />
            <path d="M37.3,15c0.4-0.8,0.7-1.7,0.7-2.6c0-3-2.4-5.4-5.4-5.4c-1.3,0-2.5,0.5-3.4,1.3C29.1,8.1,29,8.1,29,8 c0.4-0.8,0.7-1.7,0.7-2.6c0-3-2.4-5.4-5.4-5.4c-3,0-5.4,2.4-5.4,5.4c0,0.5,0.1,1,0.2,1.5c-0.1,0-0.2,0-0.3,0 c-0.5,0-0.9,0.1-1.4,0.2l7.9,7.9H37.3z" />
            <polygon points="37.1,26.8 38.6,18.1 28.4,18.1" />
        </SvgIcon>
    );
}
