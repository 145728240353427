import * as React from "react";

import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";

import fetchApi, { logFetchError } from "../../../util/fetchApi";

interface Props {
    open: boolean;
    onClose: (playerId?: string) => void;
}

export default function APTAIdDialog({ open, onClose }: Props): JSX.Element {
    const [aptaId, setAptaId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    const onSubmit = React.useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage("");
        let playerId;
        try {
            const res = await fetchApi<{ playerId: string }>("/api/pti/register", "POST", {
                aptaPlayerId: aptaId.trim(),
            });
            ({ playerId } = res);
        } catch (err) {
            logFetchError(err);
            setErrorMessage((err as Error).message);
            return;
        }

        onClose(playerId);
    }, [aptaId, onClose]);

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            PaperProps={{
                onSubmit,
                component: "form",
            }}
        >
            <DialogContent>
                <DialogContentText>
                    Enter Your APTA ID to view PTI data including wins, losses, spreads, and more.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="APTA ID"
                    name="aptaId"
                    id="aptaId"
                    value={aptaId}
                    onChange={(e) => setAptaId(e.currentTarget.value)}
                    helperText={errorMessage || undefined}
                    error={!!errorMessage}
                    fullWidth
                    variant="standard"
                />
                <DialogContentText>
                    Not sure where to find your APTA ID? Click
                    {" "}
                    <Link
                        href="https://www.platformtennisonline.org/MemberNumberLookup.aspx"
                        target="_blank"
                    >
                        here
                    </Link>
                    {" "}
                    to find your ID (you will be redirected to the official APTA website).
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={() => onClose()}>Cancel</Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    startIcon={<DoneIcon />}
                    disabled={aptaId.length < 4}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
