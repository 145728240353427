import padelHeader from "../../static/img/backgrounds/padel.jpg";
import pickleballHeader from "../../static/img/backgrounds/pickleball.jpg";
import platformHeader from "../../static/img/backgrounds/platform.jpg";
import tennisHeader from "../../static/img/backgrounds/tennis.jpg";

import { Sport } from "./context/sport";

export type KnownImage = "header";

export const BackgroundImageLookup: Record<Sport, Record<KnownImage, string>> = {
    PADEL: {
        header: padelHeader as string,
    },
    PLATFORM_TENNIS: {
        header: platformHeader as string,
    },
    TENNIS: {
        header: tennisHeader as string,
    },
    PICKLEBALL: {
        header: pickleballHeader as string,
    },
};
