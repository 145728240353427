import * as React from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Container, { ContainerProps } from "@mui/material/Container";

import type { UserEvent } from "@volley/shared/events/user-event";

import logger from "../../log";
import { pairedFetchApi } from "../../util/fetchApi";
import useDialog from "../Dialog/useDialog";
import SportHeader from "../SportHeader";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";

import TopNavBar from "./TopNavBar";
import { trainerCompatibleWithSport, useSelectedSport } from "./context/sport";

export default function UserPage(
    { children, ...props }: ContainerProps,
): JSX.Element {
    const { status } = useStatus();
    const location = useLocation();
    const { dialogType, setDialogType, batteryWarningDismissed } = useDialog();
    const [reconnectAttempts, setReconnectAttempts] = React.useState(0);
    const { status: pairingStatus } = usePairingContext();
    const { selected: selectedSport, hasSelected } = useSelectedSport();

    React.useEffect(() => {
        if (pairingStatus === "paired"
            && (dialogType === "Device" || dialogType === null)) {
            if (!trainerCompatibleWithSport(selectedSport, status)) {
                setDialogType("InvalidSportSelection");
            }
        }
    }, [pairingStatus, status, selectedSport, dialogType, setDialogType]);

    // setup event source for notifications
    React.useEffect(() => {
        const eventSrc = new EventSource("/api/events/user");
        eventSrc.onopen = (e: Event) => {
            logger.info("[UserPage] SOS event source opened", e);
        };

        eventSrc.onmessage = async (e: MessageEvent<string>) => {
            const parsedData = JSON.parse(e.data) as UserEvent;
            parsedData.timestamp = new Date(parsedData.timestamp);
            if (parsedData.data.type === "sos" && parsedData.userId) {
                logger.info("[UserPage] SOS event source message", e);
                setDialogType("NeedHelp");
                if (status?.clientId) {
                    // if we're in a session, pause potential workout
                    await pairedFetchApi(status?.clientId, "/api/apps/workouts/pause", "POST");
                }
            } else if (parsedData.data.type === "close") {
                eventSrc.close();
            }
        };

        eventSrc.onerror = (e: Event) => {
            eventSrc?.close();
            logger.warn("[UserPage] SOS event source error", e);

            setTimeout(() => {
                logger.info(`[UserPage] Reconnecting to event source, attempt ${reconnectAttempts + 1}`);
                setReconnectAttempts((current) => current + 1);
            }, Math.min(reconnectAttempts + 1 * 1000, 10_000)); // backoff but max 10s
        };

        return () => {
            eventSrc?.close();
        };
    }, [reconnectAttempts, setDialogType, status?.clientId]);

    React.useEffect(() => {
        if (status?.trainer.battery.level
            && status?.ready !== "INIT"
            && status?.trainer.battery.level <= 30
            && dialogType !== "NoConfirmReplaceBatteries"
            && !batteryWarningDismissed) {
            setDialogType("LowBatteryWarning");
        }
    }, [status, batteryWarningDismissed, dialogType, setDialogType]);

    React.useEffect(() => {
        if (!hasSelected) {
            logger.info("[UserPage] No sport selected, showing welcome dialog");
            setDialogType("SportSelectorWelcome");
        }
    }, [hasSelected, setDialogType]);

    return (
        <Box component="div" sx={{ backgroundColor: "white", height: "100%" }}>
            <TopNavBar />
            {location.pathname === "/" && (
                <SportHeader />
            )}
            <Box component="main">
                <Container maxWidth="xs" {...props} sx={{ padding: "0 4px 0 4px" }}>
                    {children}
                </Container>
            </Box>
        </Box>
    );
}
