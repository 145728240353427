import * as React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { CampaignClubMember, CampaignClub, DashboardResult } from "@volley/data/dist/types/campaign-club";

import { terseDateFormat } from "../../util";
import fetchApi from "../../util/fetchApi";

import CampaignClubThermometer from "./CampaignClubThermometer";

function memberCountText(c: number): string {
    if (c <= 5) return "Great Start!";
    if (c <= 10) return "Heating Up!";
    if (c <= 15) return "Let’s Gooo!";
    if (c <= 20) return "Over Halfway!";
    if (c <= 25) return "Here We Go!";
    if (c < 30) return "Almost there!";
    return "It’s trial time!";
}

export default function CampaignClubDashboard(): JSX.Element {
    const params = useParams<"trackingId">();
    const [club, setClub] = React.useState<CampaignClub | null>(null);
    const [members, setMembers] = React.useState<CampaignClubMember[]>([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [shareOpen, setShareOpen] = React.useState(false);

    React.useEffect(() => {
        fetchApi<DashboardResult>(`/api/campaign-clubs/t/${params.trackingId ?? ""}`)
            .then((data) => {
                setClub(data.campaignClub);
                setMembers(data.members);
            })
            .catch((e: Error) => {
                setErrorMessage(e.message);
            }).finally(() => {
                setLoading(false);
            });
    }, [params.trackingId]);

    const onShareLinkCopy = () => {
        window.navigator.clipboard.writeText(window.location.href)
            .then(() => setShareOpen(true))
            .catch((e: Error) => setErrorMessage(e.message));
    };

    const hasJoinedClub = React.useMemo(
        () => window.localStorage.getItem("volley.campaignClubs.joined") === "true",
        [],
    );

    if (errorMessage) {
        return <Typography color="error.main">{errorMessage}</Typography>;
    }

    if (loading || !club) {
        return (
            <Box component="div" display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h2" component="h1" color="primary" align="center">{club.name}</Typography>
            <Typography variant="h3" component="h2" color="primary" align="center">
                {`${club.city}, ${club.state}`}
            </Typography>
            <Typography align="center">
                Did a friend send you to this page?
                <br />
                <Link href="https://www.youtube.com/watch?v=uQfrkmG8zdQ" target="_blank">
                    Learn a little about Volley.
                </Link>
            </Typography>
            {hasJoinedClub ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onShareLinkCopy}
                    title="Copy share link to clipboard"
                    startIcon={<ContentCopyIcon />}
                >
                    Copy Share Link
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ color: "primary.main" }}
                    component={RouterLink}
                    to="../"
                    state={{
                        referredByMemberTrackingId: params.trackingId?.split("member_")[1] ?? null,
                    }}
                >
                    Join Now!
                </Button>
            )}
            {club.news && (
                <Stack spacing={1}>
                    <Typography variant="h4" component="h2" color="primary">From the Volley Team:</Typography>
                    <Typography sx={{ whiteSpace: "pre-line" }}>{club.news}</Typography>
                </Stack>
            )}
            <Divider />
            <Typography
                variant="h3"
                component="h2"
                color="primary"
                align="center"
            >
                30 Members, 30 day free trial!
            </Typography>
            <Grid container>
                <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Typography gutterBottom sx={{ fontWeight: 600 }} color="primary">
                        {memberCountText(members.length)}
                    </Typography>
                    <CampaignClubThermometer
                        ticks={30}
                        tickStyle={{
                            width: 60,
                            height: 8,
                            radius: 4,
                            margin: 4,
                        }}
                        min={0}
                        max={30}
                        value={members.length}
                    />
                </Grid>
                <Grid item xs={8}>
                    <List sx={{ pt: 0 }}>
                        {members.map((m) => (
                            <ListItem key={m.id} sx={{ py: 0 }}>
                                <ListItemText
                                    primary={`${m.firstName} ${m.lastName.slice(0, 1)}.`}
                                    secondary={terseDateFormat(new Date(m.createdAt))}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
            <Snackbar open={shareOpen} autoHideDuration={3_000} onClose={() => setShareOpen(false)}>
                <Alert severity="info" variant="filled">Link Copied!</Alert>
            </Snackbar>
        </Stack>
    );
}
