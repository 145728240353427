import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FirebaseError } from "firebase/app";
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import Password from "../common/Password";
import { VolleyButton } from "../common/buttons";

interface ResetPasswordState {
    code: string;
    apiKey: string
}

export default function ResetPassword(): JSX.Element {
    const location = useLocation();
    const state = location.state as ResetPasswordState | undefined;
    const navigate = useNavigate();

    const [password, setPassword] = React.useState("");
    const [verified, setVerified] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [verificationError, setVerificationError] = React.useState("");
    const [resetError, setResetError] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        async function verifyCode(c: string): Promise<void> {
            const auth = getAuth();
            try {
                await verifyPasswordResetCode(auth, c);
                setVerified(true);
            } catch (e) {
                setVerified(false);
                if (e instanceof FirebaseError) {
                    switch (e.code) {
                        case "auth/expired-action-code":
                            setVerificationError("Code is expired.");
                            break;
                        case "auth/invalid-action-code":
                            setVerificationError("Invalid or used code.");
                            break;
                        default:
                            setVerificationError("Error reseting your password.");
                            break;
                    }
                } else {
                    // generic error
                    setVerificationError("Error reseting your password.");
                }
            }
        }

        const code = state?.code ?? "";
        void verifyCode(code);
    }, [state]);

    const formValid = React.useMemo(
        () => password && verified && !verificationError,
        [password, verified, verificationError],
    );

    const onSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const auth = getAuth();
        const code = (state as ResetPasswordState)?.code;

        try {
            setResetError("");
            await confirmPasswordReset(auth, code, password);
            setDialogOpen(true);
        } catch (err) {
            setResetError("Error resetting your password.");
        } finally {
            setLoading(false);
        }
    }, [password, state]);

    if (verificationError) {
        return (
            <Typography color="error.main">{verificationError}</Typography>
        );
    }

    return (
        <>
            <Typography variant="h3" component="h2">
                Choose a new password
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" mb={2}>
                Please enter a new password.
            </Typography>
            <Stack
                spacing={4}
                component="form"
                onSubmit={onSubmit}
            >
                <Password onPasswordChange={setPassword} />
                <VolleyButton type="submit" disabled={!formValid || loading}>Reset</VolleyButton>
                {resetError && <Typography variant="body2" color="error.main">{resetError}</Typography>}
            </Stack>

            <Dialog
                open={dialogOpen}
                disableEscapeKeyDown
            >
                <CloseableDialogTitle onClose={() => navigate("/")}>
                    <Typography variant="h4">
                        Password Reset
                    </Typography>
                </CloseableDialogTitle>
                <DialogContent dividers>
                    <DialogContentText variant="body2" mb={2} align="center" color="black">
                        Your password has been reset. You can now log in.
                    </DialogContentText>
                    <VolleyButton onClick={() => navigate("/")}>
                        OK
                    </VolleyButton>
                </DialogContent>
            </Dialog>
        </>
    );
}
