import * as React from "react";

export interface DebugState {
    enabled: boolean;
    setEnabled: (updated: boolean) => void;
}

export const DebugContext = React.createContext<DebugState>({
    enabled: false,
    setEnabled: () => { },
});

export const DebugProvider = React.memo(({ children }: React.PropsWithChildren) => {
    const [enabled, setEnabled] = React.useState(false);

    const value = React.useMemo<DebugState>(() => ({
        enabled,
        setEnabled,
    }), [enabled]);

    return (
        <DebugContext.Provider value={value}>
            {children}
        </DebugContext.Provider>
    );
});

export function useDebugMode(): DebugState {
    return React.useContext(DebugContext);
}
