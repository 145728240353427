import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import SportPicker from "./SportPicker";
import { BackgroundImageLookup } from "./common/NavigationCardImages";
import { useSelectedSport } from "./common/context/sport";
import { useCurrentUser } from "./hooks/currentUser";

export default function SportHeader(): JSX.Element {
    const { isAdmin } = useCurrentUser();
    const { selected } = useSelectedSport();

    return (
        <Box
            component="div"
            sx={{
                position: "sticky",
                top: 56,
                left: 0,
                width: "100%",
                height: "80px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${BackgroundImageLookup[selected].header})`,
                maskImage: "linear-gradient(to bottom, black 80%, transparent)",
                maskComposite: "intersect",
                zIndex: (t) => t.zIndex.appBar,
                marginBottom: "10px",
            }}
        >
            <Stack
                direction="row"
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    component="div"
                    sx={{
                        width: isAdmin() ? "calc(100% - 35px)" : "100%",
                        padding: "20px",
                    }}
                >
                    <SportPicker backdrop="dark" fullWidth={false} />
                </Box>
                {isAdmin() && (
                    <Box component="div">
                        <IconButton
                            color="primary"
                            size="large"
                            sx={{
                                filter: "drop-shadow(8px 8px 8px #3CE97C)",
                            }}
                            component={RouterLink}
                            to="/admin"
                        >
                            <AdminPanelSettingsIcon fontSize="large" />
                        </IconButton>
                    </Box>
                )}
            </Stack>
        </Box>
    );
}
