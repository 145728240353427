import * as React from "react";

import logger from "../../log";
import fetchApi, { pairedFetchApi, logFetchError } from "../../util/fetchApi";

import { useStatus } from "./status";

export type FeatureName = string;

export const TrainerFeatureContext = React.createContext<FeatureName[]>([]);

export const TrainerFeatureProvider = React.memo(({ children }: React.PropsWithChildren) => {
    const [requested, setRequested] = React.useState(false);
    const [features, setFeatures] = React.useState<FeatureName[]>([]);

    const { status } = useStatus();

    React.useEffect(() => {
        async function checkRequiredFeatures() {
            if (status?.clientId) {
                const result = await pairedFetchApi<string[]>(status?.clientId, "/api/features");
                logger.info(`Available trainer features: ${result.join(",")}`);
                setFeatures(result);
            }
        }
        if (!requested && status?.clientId) {
            setRequested(true);
            checkRequiredFeatures().catch((e) => {
                logFetchError(e);
            });
        }
    }, [status?.clientId, requested]);

    return (
        <TrainerFeatureContext.Provider value={features}>
            {children}
        </TrainerFeatureContext.Provider>
    );
});

export function useTrainerFeatures(): FeatureName[] {
    return React.useContext(TrainerFeatureContext);
}

export function useTrainerFeaturesAdmin(clientId: number): FeatureName[] {
    const [features, setFeatures] = React.useState<FeatureName[]>([]);

    React.useEffect(() => {
        async function checkRequiredFeatures() {
            if (clientId) {
                const result = await fetchApi<string[]>(`/trainer/${clientId}/api/features`);
                logger.info(`Available trainer features: ${result.join(",")}`);
                setFeatures(result);
            }
        }

        checkRequiredFeatures().catch((e) => {
            logFetchError(e);
        });
    }, [clientId]);

    return features;
}
