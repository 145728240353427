import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function CampaignClubEmailSent(): JSX.Element {
    return (
        <Stack spacing={2}>
            <Typography variant="h3" component="h1" color="primary">Thank you!</Typography>
            <Typography>Please check your email for next steps.</Typography>
        </Stack>
    );
}
