import * as React from "react";
import { To, useLocation, useNavigate } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircleIcon from "@mui/icons-material/Circle";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";

// TODO: move these elsewhere
import logger from "../../log";
import { pairedFetchApi } from "../../util/fetchApi";
import useDialog from "../Dialog/useDialog";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";
import useFreshchat from "../hooks/useFreshchat";

import VolleyV from "./icons/VolleyV";

export interface TopBarProps {
    optionalText?: string;
}

type BackNavState = "ready" | "waiting";

export default function TopBar({ optionalText }: TopBarProps): JSX.Element {
    const freshChat = useFreshchat();
    const { status: pairingStatus, promptToPair, pairingCancelled } = usePairingContext();
    const { dialogType, setDialogType, forceDeviceDialog } = useDialog();
    const { status } = useStatus();
    const headerText = optionalText || `Trainer ${status?.clientId ?? "?"}`;
    const navigate = useNavigate();
    const location = useLocation();

    const [backReadyState, setBackReadyState] = React.useState<BackNavState>("ready");
    const navigateTarget = React.useRef<string | number>(-1);

    const showNavigation = React.useMemo(() => {
        if (location.pathname === "/"
            || location.pathname === "/content") {
            return false;
        }

        if (dialogType === "Device") {
            return false;
        }

        return true;
    }, [location, dialogType]);

    const onHeaderClick = React.useCallback(() => {
        if (dialogType === "Device") {
            setDialogType(null);
        } else {
            setDialogType("Device");
        }
    }, [dialogType, setDialogType]);

    const showConnectButton = pairingStatus === "unpaired";

    const handleNav = React.useCallback(async () => {
        if (status?.workouts.playState === "playing") {
            logger.info("[Top Nav Bar] - Workout in play, pausing before navigating.");
            await pairedFetchApi(status?.clientId, "/api/apps/workouts/pause", "POST");
            setBackReadyState("waiting");
        } else if (backReadyState === "ready") {
            logger.info(`[Top Nav Bar] - Navigating ${navigateTarget.current} from handleNav.`);
            navigate(navigateTarget.current as To);
        } else {
            logger.info("[Top Nav Bar] - handleNav called while not in 'ready' state.");
        }
    }, [status, backReadyState, navigate]);

    React.useEffect(() => {
        if (backReadyState === "waiting") {
            logger.info("[Top Nav Bar] - Checking play state.");
            const playState = status?.workouts.playState;
            if (playState === "paused" || playState === "stopped") {
                logger.info("[Top Nav Bar] - Play state is no longer playing, navigating back.");
                setBackReadyState("ready");
                navigate(navigateTarget.current as To);
            }
        }
    }, [backReadyState, status, navigate]);

    const statusIcon = React.useMemo(() => {
        if (status && status.fault) {
            if (status.fault.failures.every((f) => f.source === "vision")) {
                return <CircleIcon color="success" />;
            }

            return <ErrorIcon color="error" />;
        }

        return <CircleIcon color="success" />;
    }, [status]);

    return (
        <AppBar
            id="volley-app-bar"
            position="sticky"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            <Toolbar sx={{ px: 0 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}
                >
                    <Box component="div" width="20%">
                        {(showNavigation && backReadyState === "ready") && (
                            <IconButton
                                onClick={async () => {
                                    navigateTarget.current = -1;
                                    await handleNav();
                                }}
                                color="inherit"
                                aria-label="back"
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        )}
                        {(showNavigation && backReadyState !== "ready" && navigateTarget.current === -1) && (
                            <CircularProgress
                                size={24}
                                color="secondary"
                                aria-busy="true"
                                role="alert"
                            />
                        )}
                    </Box>
                    <Box component="div">
                        {showConnectButton && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    logger.info(`Connect Clicked from ${location.pathname}`);
                                    promptToPair();
                                }}
                                sx={{ color: "primary.main" }}
                                startIcon={<VolleyV />}
                                fullWidth
                            >
                                Connect
                            </Button>
                        )}
                        {["requested", "validating"].includes(pairingStatus) && (
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={() => pairingCancelled()}
                                sx={{ color: "primary.main" }}
                                fullWidth
                            >
                                Cancel
                            </Button>
                        )}
                        {(pairingStatus === "paired" && dialogType === null) && (
                            <Button
                                onClick={onHeaderClick}
                                sx={{
                                    display: "inline-flex",
                                    color: "inherit",
                                    fontSize: "1.2rem",
                                }}
                                startIcon={statusIcon}
                                endIcon={<ExpandMoreIcon sx={{ color: "common.white" }} />}
                            >
                                {headerText}
                            </Button>
                        )}
                        {(pairingStatus === "paired" && dialogType === "Device") && (
                            <Button
                                onClick={() => setDialogType(null)}
                                fullWidth
                                disabled={forceDeviceDialog}
                                sx={{
                                    color: "inherit",
                                    "&.Mui-disabled": {
                                        color: (theme) => theme.palette.grey[500],
                                    },
                                }}
                                endIcon={<ExpandLessIcon />}
                            >
                                Close
                            </Button>
                        )}
                    </Box>
                    <Box component="div" width="20%" display="flex" justifyContent="flex-end">
                        <IconButton
                            onClick={() => freshChat.open()}
                            color="inherit"
                            aria-label="help"
                        >
                            <ContactSupportIcon />
                        </IconButton>
                        {showNavigation && backReadyState === "ready" && (
                            <IconButton
                                onClick={async () => {
                                    navigateTarget.current = "/";
                                    await handleNav();
                                }}
                                color="inherit"
                                aria-label="home"
                            >
                                <HomeIcon />
                            </IconButton>
                        )}
                        {(showNavigation && backReadyState !== "ready" && navigateTarget.current === "/") && (
                            <CircularProgress
                                size={24}
                                color="secondary"
                                aria-busy="true"
                                role="alert"
                            />
                        )}
                    </Box>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

TopBar.defaultProps = {
    optionalText: "",
};
