import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";
import { useLift } from "../hooks/useLift";

import useDialog from "./useDialog";

export default function ArmMovingDialog(): JSX.Element {
    const { status, networkStateCode } = useStatus();
    const { setDialogType, onClose } = useDialog();
    const {
        height, lowerHead, down, liftRange,
    } = useLift();
    const [errorMessage, setErrorMessage] = React.useState("");

    const percent = React.useMemo(() => {
        const full = Math.abs(liftRange.max - liftRange.min);
        const diff = Math.abs(height - liftRange.min);
        const inv = full - diff;
        return Math.floor((inv / full) * 100);
    }, [height, liftRange.min, liftRange.max]);

    React.useEffect(() => {
        if (down) {
            setDialogType("EmptyBallBin");
        } else {
            lowerHead().catch((e) => setErrorMessage((e as Error).message));
        }
    }, [down, setDialogType, lowerHead]);

    React.useEffect(() => {
        if (networkStateCode !== "Connected" || status?.fault) {
            setDialogType("PowerDown");
        }
    }, [networkStateCode, status?.fault, setDialogType]);

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={onClose}>
                Moving Trainer Arm
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    The trainer is lowering so the arm is in the storage position
                </DialogContentText>
                {!!errorMessage && (
                    <DialogContentText color="error.main">
                        {errorMessage}
                    </DialogContentText>
                )}
                <Box
                    sx={{
                        my: 2,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress
                        variant="determinate"
                        value={percent}
                        size={80}
                        sx={{ color: "primary.light" }}
                    />
                    <Typography
                        sx={{
                            top: "50%",
                            left: "50%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            fontSize: "24pt",
                        }}
                        color="primary.light"
                    >
                        {height}
                        <Typography component="span" sx={{ fontSize: "11pt" }}>
                            in.
                        </Typography>
                    </Typography>
                </Box>
            </DialogContent>
        </>
    );
}
