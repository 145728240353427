import * as React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { datadogRum } from "@datadog/browser-rum";
import type { FirebaseError } from "firebase/app";
import {
    getAuth,
    signOut as firebaseSignOut,
    onAuthStateChanged,
    User as FirebaseUser,
    signInWithCustomToken,
} from "firebase/auth";

import { OffsetResult } from "@volley/data";
import { Subscription } from "@volley/data/dist/types/subscription";
import type { UserWithContentProviders } from "@volley/data/dist/types/user";

import fetchApi, { logFetchError } from "../../util/fetchApi";

export interface UserRole {
    role: { name: string; };
}

export enum AuthState {
    LOADING,
    UNAUTHENTICATED,
    AUTHENTICATED,
}

interface Value {
    currentUser: UserWithContentProviders | null;
    setCurrentUser: (user: UserWithContentProviders) => void;
    authState: AuthState;
    subscriptions: Subscription[];
    refreshSubscriptions: () => void;
    error?: string;
    features: string[];
    isAdmin: () => boolean;
    isPro: () => boolean;
    signOut: () => void;
}

const DEFAULT_VALUE: Value = {
    currentUser: null,
    features: [],
    setCurrentUser: () => { },
    authState: AuthState.LOADING,
    subscriptions: [],
    refreshSubscriptions: () => { },
    isAdmin: () => false,
    isPro: () => false,
    signOut: () => { },
};

const VALID_SUBSCRIPTION_STATUSES = new Set(["active", "past_due", "incomplete"]);

export const CurrentUserContext = React.createContext<Value>(DEFAULT_VALUE);

function hasRole(roles: UserRole[], role: string): boolean {
    const matchingRoles = roles.filter((r) => r.role.name === role);
    return matchingRoles.length > 0;
}

/**
 * Check if a user has an active subscription. First, user's with an ADMIN role are always
 * considered to have an active subscription. If the user does not have an ADMIN role, then
 * their subscriptions are checked; if one of them meets the criteria of being active, then
 * this function returns true.
 *
 * @param user the user to check whether they're an admin
 * @param subscriptions the subscriptions to check if one is active
 * @returns is the user an admin OR does the user have an active subscription
 */
export function hasActiveSubscription(user: UserWithContentProviders | null, subscriptions: Subscription[]): boolean {
    return (user && hasRole(user.userRoles, "ADMIN"))
        || subscriptions.some((s) => VALID_SUBSCRIPTION_STATUSES.has(s.status));
}

interface Props {
    children: React.ReactNode;
}

interface LocationState {
    from?: string
}

type AuthenticationResponse = UserWithContentProviders & {
    isNewUser: boolean;
};

export function CurrentUserProvider({ children }: Props): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [authState, setAuthState] = React.useState(AuthState.LOADING);
    const [user, setUser] = React.useState<UserWithContentProviders | null>(null);
    const [isNewUser, setIsNewUser] = React.useState(false);
    const [features, setFeatures] = React.useState<string[] | null>(null);
    const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([]);
    const [error, setError] = React.useState<string>();

    const signOut = React.useCallback(async () => {
        await firebaseSignOut(getAuth());
        setUser(null);
    }, []);

    const handleAuthStateChange = React.useCallback(async (authUser: FirebaseUser | null) => {
        if (!authUser) {
            setUser(null);
            if (!token) setAuthState(AuthState.UNAUTHENTICATED);
            return;
        }

        const idToken = await authUser.getIdToken();

        let volleyUser: UserWithContentProviders;
        try {
            const response = await fetchApi<AuthenticationResponse>("/api/users/authentication", "POST", { idToken });
            volleyUser = response;
            if (response.isNewUser) setIsNewUser(true);
        } catch (e: unknown) {
            setError((e as Error).message);
            return;
        }

        setUser(volleyUser);
        datadogRum.setUser({
            id: String(volleyUser.id),
            email: volleyUser.email,
            name: `${volleyUser.firstName} ${volleyUser.lastName}`,
            username: volleyUser.username,
        });
        setError(undefined);
        setAuthState(AuthState.AUTHENTICATED);
    }, [token]);

    const refreshSubscriptions = React.useCallback(() => {
        if (!user?.id) return;

        fetchApi<OffsetResult<Subscription>>(`/api/users/${user.id}/subscriptions?limit=10&offset=0`)
            .then(({ result }) => {
                setSubscriptions(result);
            })
            .catch(logFetchError);
    }, [user?.id]);

    React.useEffect(() => {
        refreshSubscriptions();
    }, [refreshSubscriptions]);

    const value = React.useMemo<Value>(() => ({
        currentUser: user,
        authState,
        subscriptions,
        refreshSubscriptions,
        error,
        features: features ?? [],
        setCurrentUser: setUser,
        isAdmin: () => user !== null && hasRole(user.userRoles, "ADMIN"),
        isPro: () => user !== null && hasRole(user.userRoles, "PRO"),
        signOut,
    }), [features, subscriptions, refreshSubscriptions, user, authState, error, signOut]);

    // Observe changes to authentication state, stop listening on unmount
    React.useEffect(() => onAuthStateChanged(getAuth(), handleAuthStateChange), [handleAuthStateChange]);

    // Sign in with custom auth token
    React.useEffect(() => {
        const auth = getAuth();
        if (token && !auth.currentUser) {
            signInWithCustomToken(auth, token)
                .then(() => {
                    setSearchParams((current) => {
                        current.delete("token");
                        return current;
                    });
                })
                .catch((err: FirebaseError) => {
                    setError(err.message);
                });
        }
    }, [token, setSearchParams]);

    // Direct user into application after login
    React.useEffect(() => {
        if (user && authState === AuthState.AUTHENTICATED && location.pathname.startsWith("/login")) {
            if (isNewUser) {
                navigate("/signup/account", {
                    replace: true,
                    state: {
                        email: user.email,
                        name: `${user.firstName} ${user.lastName}`,
                    },
                });
            } else {
                const from = ((location.state as LocationState | null)?.from ?? "/");
                navigate(from, { replace: true });
            }
        }
    }, [user, isNewUser, authState, location.pathname, location.state, navigate]);

    React.useEffect(() => {
        async function fetchFeatures(id: number) {
            const results: string[] = await fetchApi(`/api/users/${id}/features`, "GET");
            setFeatures(results);
        }

        if (authState === AuthState.AUTHENTICATED && user?.id && features === null) {
            void fetchFeatures(user.id);
        }
    }, [features, authState, user?.id]);

    return (
        <CurrentUserContext.Provider value={value}>
            {children}
        </CurrentUserContext.Provider>
    );
}

export function useCurrentUser(): Value {
    return React.useContext(CurrentUserContext);
}
