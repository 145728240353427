import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import * as volleyLogo from "../../static/img/icon-volley-green.png";

interface Props {
    open?: boolean;
}

export default function Loading({ open = true }: Props): JSX.Element {
    const theme = useTheme();

    return (
        <Dialog fullScreen open={open} PaperProps={{ sx: { background: theme.palette.primary.main } }}>
            <Box component="div" sx={{ m: "auto", textAlign: "center" }}>
                <img src={volleyLogo as string} alt="green volley logo" height={60} />
                <Typography variant="h3" color="white" sx={{ mt: 2, mb: 4 }}>
                    Loading ...
                </Typography>
                <CircularProgress sx={{ color: theme.palette.secondary.main }} />
            </Box>
        </Dialog>
    );
}

Loading.defaultProps = {
    open: true,
};
