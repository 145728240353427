// Reference implementation: trainers/atom/loki/projects/rpi-4b/loki_script.py
const PLATFORMS = [
    "Unknown Platform",
    "Wall-E Gigabyte",
    "Small-E Gigabyte",
    "Atom Raspberry Pi 4b",
    "Atom Raspberry Pi CM4",
    "Atom Neousys AGX",
    "Atom PICO PCM",
    "Atom PICO HCM",
    "Atom PICO LED",
];

const TYPES = [
    "Reserved",
    "Control Software",
    "Vision OS",
    "Vision App",
    "MCU Firmware",
];

function b6(char: string): number {
    const ord = (c: string) => c.charCodeAt(0);

    const c = ord(char);

    if (c >= ord("0") && c <= ord("9")) {
        return c - ord("0");
    }

    if (c >= ord("a") && c <= ord("z")) {
        return c - ord("a") + 10;
    }

    if (c >= ord("A") && c <= ord("Z")) {
        return c - ord("A") + 36;
    }

    if (c === ord("-")) {
        return 62;
    }

    if (c === ord("~")) {
        return 63;
    }

    return NaN;
}

interface BuildLabelComponents {
    year: number;
    month: number;
    day: number;
    platform: string;
    type: string;
    major: number;
    minor: number;
    patch: number;
    alpha: number;
    release: string;
    digest: string;
    branch: string;
}

export function expandLabelToComponents(label: string): BuildLabelComponents {
    const yyyy = 2020 + b6(label[0]);
    const m = b6(label[1]);
    const d = b6(label[2]);
    const platform = PLATFORMS[b6(label[3])];
    const type = TYPES[b6(label[4])];
    const major = b6(label[5]);
    const minor = b6(label[6]);
    const patch = b6(label[7]);
    const alpha = b6(label[8]);
    const release = alpha === 0 ? "-pro" : `-alpha.${alpha}`;
    const digest = label.slice(12, 20);
    const branch = label.slice(20).replace(/_*$/g, "");

    return {
        year: yyyy,
        month: m,
        day: d,
        platform,
        type,
        major,
        minor,
        patch,
        alpha,
        release,
        digest,
        branch,
    };
}

export function buildLabelToVersionString(label: string): string {
    const components = expandLabelToComponents(label);

    let suffix = "";
    if (components.alpha !== 0) {
        suffix = `alpha.${components.alpha}`;
    }

    return `${components.major}.${components.minor}.${components.patch}${suffix}`;
}

export function expand(label?: string | null): string {
    if (!label) {
        return "";
    }

    const components = expandLabelToComponents(label);

    const yyyy = components.year;
    const m = components.month;
    const mm = m < 10 ? `0${m}` : m;
    const d = components.day;
    const dd = d < 10 ? `0${d}` : d;
    const {
        platform,
        type,
        major,
        minor,
        patch,
        release,
        digest,
        branch,
    } = components;

    return `${yyyy}-${mm}-${dd} ${major}.${minor}.${patch}${release} ${platform} ${type} ${branch} ${digest}`;
}

/**
 * Compares two semantic versioning strings and determines if the first version is greater than the second.
 *
 * @param {string} version1 - The first version string in the format 'major.minor.patch'.
 * @param {string} version2 - The second version string in the format 'major.minor.patch'.
 * @returns {boolean} Returns `true` if `version1` is greater than `version2`, otherwise returns `false`.
 * @throws {Error} Throws an error if any of the provided version strings have an invalid format.
 */
export function isVersionGreater(version1: string, version2: string): boolean {
    const parseVersion = (version: string): [number, number, number] => {
        const matches = version.match(/^(\d+)\.(\d+)\.(\d+)/);
        if (!matches) {
            throw new Error(`Invalid version format: ${version}`);
        }
        const [, major, minor, patch] = matches;
        return [parseInt(major, 10), parseInt(minor, 10), parseInt(patch, 10)];
    };

    const [major1, minor1, patch1] = parseVersion(version1);
    const [major2, minor2, patch2] = parseVersion(version2);

    if (major1 > major2) return true;
    if (major1 < major2) return false;

    if (minor1 > minor2) return true;
    if (minor1 < minor2) return false;

    if (patch1 > patch2) return true;

    return false;
}
