import * as React from "react";

import SignalCellular0BarIcon from "@mui/icons-material/SignalCellular0Bar";
import SignalCellular1BarIcon from "@mui/icons-material/SignalCellular1Bar";
import SignalCellular2BarIcon from "@mui/icons-material/SignalCellular2Bar";
import SignalCellular3BarIcon from "@mui/icons-material/SignalCellular3Bar";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";

import { SystemStatusCellular } from "@volley/shared/coach-models";

export default function CellularStatusIcon(
    { cellularStatus }: { cellularStatus: SystemStatusCellular | null },
): JSX.Element {
    if (!cellularStatus) {
        return <SignalCellularConnectedNoInternet0BarIcon />;
    }

    // new metric for network quality used by motion for the billboard
    if (cellularStatus.score) {
        if (cellularStatus.score < 20) {
            return <SignalCellular1BarIcon color="warning" />;
        }

        if (cellularStatus.score < 40) {
            return <SignalCellular1BarIcon color="success" />;
        }

        if (cellularStatus.score < 60) {
            return <SignalCellular2BarIcon color="success" />;
        }

        if (cellularStatus.score < 80) {
            return <SignalCellular3BarIcon color="success" />;
        }

        if (cellularStatus.score >= 80) {
            return <SignalCellular4BarIcon color="success" />;
        }
    }

    // backwards compat for trainers without the new metric in coach status
    if (cellularStatus.rssi) {
        if (cellularStatus.rssi > -70) {
            return <SignalCellular3BarIcon color="success" />;
        }

        if (cellularStatus.rssi > -85) {
            return <SignalCellular2BarIcon color="warning" />;
        }

        if (cellularStatus.rssi > -95) {
            return <SignalCellular1BarIcon color="warning" />;
        }
    }

    return <SignalCellular0BarIcon color="error" />;
}
