import * as React from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import Typography, { TypographyProps as MuiTypographyProps } from "@mui/material/Typography";

import useFreshchat from "../hooks/useFreshchat";

type Props = ButtonProps & {
    preText?: string;
    TypographyProps?: MuiTypographyProps;
};

export default function SupportLink(
    {
        TypographyProps, preText, children, ...props
    }: Props,
): JSX.Element {
    const freshchat = useFreshchat();

    return (
        <Typography variant="body2" align="center" sx={{ my: 2 }} {...TypographyProps}>
            {`${preText ?? ""} `}
            <Button
                {...props}
                type="button"
                onClick={() => freshchat.open()}
                variant="text"
                color="info"
            >
                {children}
            </Button>
        </Typography>
    );
}
SupportLink.defaultProps = { TypographyProps: {}, preText: "" };
