import * as React from "react";

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export default function useInterval(cb: () => void, delay: number | null): number | undefined {
    const savedCallback = React.useRef<() => void>();
    const id = React.useRef<number>();

    React.useEffect(() => {
        savedCallback.current = cb;
    }, [cb]);

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (delay !== null) {
            id.current = window.setInterval(tick, delay);
        }
        return () => clearInterval(id.current);
    }, [delay]);

    return id.current;
}
