import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import fetchApi, { logFetchError } from "../../util/fetchApi";

interface LocationState {
    campaignClubTrackingId?: string;
    referredByMemberTrackingId?: string;
}

export default function CampaignClubLanding(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as LocationState | undefined;
    const [email, setEmail] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        setErrorMessage("");
        try {
            await fetchApi(
                "/api/campaign-clubs/email-confirmations",
                "POST",
                {
                    email: email.trim(),
                    referredByMemberTrackingId: locationState?.referredByMemberTrackingId ?? null,
                },
            );
            navigate("./email-sent");
        } catch (err) {
            setErrorMessage("There was an error sending the email. Please try again later.");
            logFetchError(err);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Stack spacing={2} component="form" onSubmit={onSubmit}>
            <Typography variant="h2" component="h1" color="primary">Welcome!</Typography>
            <Typography>
                Interested in seeing what Volley is all about at your club?
            </Typography>
            <Typography>
                <u>We’ve got an offer for you</u>
                <span>: Get 30 club friends together and get a free 30 day trial!</span>
            </Typography>
            <Typography>
                Sound good? Let’s get you to your club page. Please enter your email below.
            </Typography>
            <TextField
                label="Email"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                required
                helperText={errorMessage}
                error={!!errorMessage}
            />
            <Button type="submit" fullWidth variant="contained" disabled={email.length < 5 || submitting}>
                Submit
            </Button>
        </Stack>
    );
}
