import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

type Props = DialogTitleProps & {
    children?: React.ReactNode;
    onClose?: () => void;
};

export default function CloseableDialogTitle({ children, onClose, ...other }: Props): JSX.Element {
    return (
        <DialogTitle sx={{ p: 2 }} color="primary.main" {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 6,
                        top: 6,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

CloseableDialogTitle.defaultProps = {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    children: <></>,
    onClose: undefined,
};
