import * as React from "react";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import SupportLink from "../common/SupportLink";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";

import useDialog from "./useDialog";

export default function PowerDownDialog(): JSX.Element {
    const { status } = useStatus();
    const { onClose } = useDialog();
    const { unpair } = usePairingContext();
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        async function shutdown() {
            await pairedFetchApi(status?.clientId, "/api/shutdown", "POST");
        }

        shutdown()
            .catch((e) => {
                logFetchError(e, "API call failed from PowerDownDialog");
                setErrorMessage((e as Error).message);
            })
            .finally(() => {
                unpair();
            });
    }, [status?.clientId, unpair]);

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={onClose}>
                Power Down Trainer
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please push the large red button on the base of the trainer to finish turning it off.
                </DialogContentText>
                {!!errorMessage && <DialogContentText color="error.main">{errorMessage}</DialogContentText>}
            </DialogContent>
            <SupportLink preText="Having Trouble?">
                Click here.
            </SupportLink>
        </>
    );
}
