export const STATES = [
    { name: "AL - Alabama", abbreviation: "AL" },
    { name: "AK - Alaska", abbreviation: "AK" },
    { name: "AZ - Arizona", abbreviation: "AZ" },
    { name: "AR - Arkansas", abbreviation: "AR" },
    { name: "AS - American Samoa", abbreviation: "AS" },
    { name: "CA - California", abbreviation: "CA" },
    { name: "CO - Colorado", abbreviation: "CO" },
    { name: "CT - Connecticut", abbreviation: "CT" },
    { name: "DC - District of Columbia", abbreviation: "DC" },
    { name: "DE - Delaware", abbreviation: "DE" },
    { name: "FL - Florida", abbreviation: "FL" },
    { name: "GA - Georgia", abbreviation: "GA" },
    { name: "HI - Hawaii", abbreviation: "HI" },
    { name: "ID - Idaho", abbreviation: "ID" },
    { name: "IL - Illinois", abbreviation: "IL" },
    { name: "IN - Indiana", abbreviation: "IN" },
    { name: "IA - Iowa", abbreviation: "IA" },
    { name: "KS - Kansas", abbreviation: "KS" },
    { name: "KY - Kentucky", abbreviation: "KY" },
    { name: "LA - Louisiana", abbreviation: "LA" },
    { name: "ME - Maine", abbreviation: "ME" },
    { name: "MD - Maryland", abbreviation: "MD" },
    { name: "MA - Massachusetts", abbreviation: "MA" },
    { name: "MI - Michigan", abbreviation: "MI" },
    { name: "MN - Minnesota", abbreviation: "MN" },
    { name: "MS - Mississippi", abbreviation: "MS" },
    { name: "MO - Missouri", abbreviation: "MO" },
    { name: "MT - Montana", abbreviation: "MT" },
    { name: "NE - Nebraska", abbreviation: "NE" },
    { name: "NV - Nevada", abbreviation: "NV" },
    { name: "NH - New Hampshire", abbreviation: "NH" },
    { name: "NJ - New Jersey", abbreviation: "NJ" },
    { name: "NM - New Mexico", abbreviation: "NM" },
    { name: "NY - New York", abbreviation: "NY" },
    { name: "NC - North Carolina", abbreviation: "NC" },
    { name: "ND - North Dakota", abbreviation: "ND" },
    { name: "MP - Northern Mariana Islands", abbreviation: "MP" },
    { name: "OH - Ohio", abbreviation: "OH" },
    { name: "OK - Oklahoma", abbreviation: "OK" },
    { name: "OR - Oregon", abbreviation: "OR" },
    { name: "PA - Pennsylvania", abbreviation: "PA" },
    { name: "PR - Puerto Rico", abbreviation: "PR" },
    { name: "RI - Rhode Island", abbreviation: "RI" },
    { name: "SC - South Carolina", abbreviation: "SC" },
    { name: "SD - South Dakota", abbreviation: "SD" },
    { name: "TN - Tennessee", abbreviation: "TN" },
    { name: "TX - Texas", abbreviation: "TX" },
    { name: "UM - United States Minor Outlying Islands", abbreviation: "UM" },
    { name: "UT - Utah", abbreviation: "UT" },
    { name: "VT - Vermont", abbreviation: "VT" },
    { name: "VI - Virgin Islands", abbreviation: "VI" },
    { name: "VA - Virginia", abbreviation: "VA" },
    { name: "WA - Washington", abbreviation: "WA" },
    { name: "WV - West Virginia", abbreviation: "WV" },
    { name: "WI - Wisconsin", abbreviation: "WI" },
    { name: "WY - Wyoming", abbreviation: "WY" },
    { name: "AA - Armed Forces Americas", abbreviation: "AA" },
    { name: "AE - Armed Forces Africa", abbreviation: "AE" },
    { name: "AE - Armed Forces Canada", abbreviation: "AE" },
    { name: "AE - Armed Forces Europe", abbreviation: "AE" },
    { name: "AE - Armed Forces Middle East", abbreviation: "AE" },
    { name: "AP - Armed Forces Pacific", abbreviation: "AP" },
];

export const GENDER_OPTIONS: Array<{ label: string, value: string }> = [
    {
        label: "Female",
        value: "Female",
    },
    {
        label: "Male",
        value: "Male",
    },
    {
        label: "Non-binary/Non-conforming",
        value: "Non-binary",
    },
    {
        label: "Prefer not to respond",
        value: "No response",
    },
];

export const USER_TAG_OPTIONS = ["Player", "Pro", "GM", "Other"];
