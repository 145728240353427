import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import VolleyV from "../common/icons/VolleyV";

import CampaignClubConfirm from "./CampaignClubConfirm";
import CampaignClubCreate from "./CampaignClubCreate";
import CampaignClubDashboard from "./CampaignClubDashboard";
import CampaignClubEmailSent from "./CampaignClubEmailSent";
import CampaignClubJoin from "./CampaignClubJoin";
import CampaignClubLanding from "./CampaignClubLanding";

export default function CampaignClubs(): JSX.Element {
    return (
        <Box>
            <AppBar position="static">
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <IconButton color="secondary" component={Link} to="/campaigns">
                            <VolleyV />
                        </IconButton>
                        <Typography variant="h3" component="h1">Club Campaigns</Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container maxWidth="lg" sx={{ px: 0 }}>
                <Paper sx={{ p: 2, pb: 12 }}>
                    <Routes>
                        <Route path="" element={<CampaignClubLanding />} />
                        <Route path="email-sent" element={<CampaignClubEmailSent />} />
                        <Route path="t/:trackingId" element={<CampaignClubDashboard />} />
                        <Route path="confirm/:token" element={<CampaignClubConfirm />} />
                        <Route path="create" element={<CampaignClubCreate />} />
                        <Route path="join" element={<CampaignClubJoin />} />
                    </Routes>
                </Paper>
            </Container>
        </Box>
    );
}
