import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FirebaseError } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
} from "firebase/auth";
import backgroundImage from "url:../static/img/background-gradient-curves.svg";

import volleyLogoImg from "../static/img/logo-volley-square-white.png";
import theme from "../theme";

import { AppleSignInButton, GoogleSignInButton, VolleyButton } from "./common/buttons";
import { useCurrentUser } from "./hooks/currentUser";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const LoginTextField = styled(TextField)({
    "&.MuiFormControl-root .Mui-disabled": {
        color: "#808799",
        fieldset: { borderColor: "#808799" },
    },
    "& label": {
        color: "#a8b8d9",
    },
    "& label.Mui-focused": {
        color: theme.palette.secondary.main,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root": {
        // eslint-disable-next-line max-len
        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
            WebkitBoxShadow: `0 0 0 30px ${theme.palette.primary.light} inset`,
        },
        "input:-webkit-autofill": {
            WebkitTextFillColor: theme.palette.common.white,
        },
        "& .MuiIconButton-root": {
            color: theme.palette.primary.light,
        },
        "& input": {
            color: theme.palette.common.white,
        },
        "& fieldset": {
            borderColor: "#a8b8d9",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.secondary.main,
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.secondary.main,
        },
    },
    "& .MuiOutlinedInput-root:active, & .MuiOutlinedInput-root:focus, & .MuiOutlinedInput-root:focus-within": {
        "& .MuiIconButton-root": {
            color: theme.palette.secondary.main,
        },
    },
});

const FirebaseErrorMap: { [code: string]: string } = {
    "auth/popup-closed-by-user": "Popup closed before logging in",
    "auth/user-not-found": "Invalid email and password combination. Please try again.",
    "auth/wrong-password": "Invalid email and password combination. Please try again.",
};

export default function Login(): JSX.Element {
    const { error: currentUserError } = useCurrentUser();
    const desktopMatch = useMediaQuery(theme.breakpoints.up("md"));
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [authError, setAuthError] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    const onSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitting(true);
        setEmailError("");
        setPasswordError("");

        let errorCount = 0;
        if (email.length < 5 || !email.includes("@")) {
            setEmailError("Invalid email");
            errorCount += 1;
        }

        if (password.length < 6) {
            setPasswordError("Invalid password");
            errorCount += 1;
        }

        if (errorCount) {
            setSubmitting(false);
            return;
        }

        try {
            await signInWithEmailAndPassword(getAuth(), email, password);
        } catch (err: unknown) {
            const error = err as FirebaseError;
            setAuthError((FirebaseErrorMap[error.code]) ?? error.message.slice(0, 100));
        } finally {
            setSubmitting(false);
        }
    }, [email, password]);

    const onSignInWithGoogle = React.useCallback(async () => {
        setSubmitting(true);
        try {
            await signInWithPopup(getAuth(), googleProvider);
        } catch (err: unknown) {
            const error = err as FirebaseError;
            setAuthError((FirebaseErrorMap[error.code]) ?? error.message.slice(0, 100));
        } finally {
            setSubmitting(false);
        }
    }, []);

    const onSignInWithApple = React.useCallback(async () => {
        setSubmitting(true);
        try {
            await signInWithPopup(getAuth(), appleProvider);
        } catch (err: unknown) {
            const error = err as FirebaseError;
            setAuthError((FirebaseErrorMap[error.code]) ?? error.message.slice(0, 100));
        } finally {
            setSubmitting(false);
        }
    }, []);

    return (
        <Grid
            container
            alignItems={desktopMatch ? "center" : "flex-end"}
            sx={{
                background: desktopMatch
                    ? theme.palette.primary.main
                    : "linear-gradient(157deg, rgba(32, 67, 136, 1) 0%, rgba(24, 39, 79, 1) 100%)",
                backgroundImage: desktopMatch
                    ? `url('${backgroundImage as unknown as string}')`
                    : undefined,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "left",
                minHeight: "100vh",
                boxShadow: "3px 4px 8px 4px rgb(0, 0, 0, 0.2)",
                py: 2,
            }}
        >
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
                <img src={volleyLogoImg as string} width="45%" alt="Volley logo" />
            </Grid>
            <Grid item xs={12} md={5} component="form" onSubmit={onSubmit}>
                <Box component="div" sx={{ mx: 4, mb: 1 }}>
                    <Typography color="error.light">{currentUserError || authError}</Typography>
                    <LoginTextField
                        type="email"
                        id="email"
                        label="Email"
                        fullWidth
                        margin="normal"
                        disabled={submitting}
                        error={!!emailError}
                        helperText={emailError ?? undefined}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        sx={{ color: theme.palette.text.secondary, my: 2 }}
                    />
                    <LoginTextField
                        type={showPassword ? "text" : "password"}
                        id="password"
                        label="Password"
                        fullWidth
                        margin="normal"
                        disabled={submitting}
                        error={!!passwordError}
                        helperText={passwordError ?? undefined}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{ fontSize: 13, mb: 2 }}
                    >
                        <Link
                            color={theme.palette.secondary.main}
                            underline="none"
                            component={RouterLink}
                            to="/auth/forgot-password"
                        >
                            Forgot password?
                        </Link>
                    </Typography>
                </Box>

                <Box component="div" sx={{ my: 1, mx: 4 }}>
                    <VolleyButton type="submit" disabled={submitting}>LOG IN</VolleyButton>
                </Box>
                <Typography
                    variant="body2"
                    align="center"
                    sx={{
                        color: theme.palette.common.white,
                        my: 3,
                        fontSize: 13,
                        px: 8,
                    }}
                >
                    Not yet a member? No problem!
                    <br />
                    <Link
                        color={theme.palette.secondary.main}
                        underline="none"
                        component={RouterLink}
                        to="../signup"
                        fontWeight="bold"
                    >
                        Create an account today.
                    </Link>
                </Typography>
                <Divider sx={{ my: 3, mx: 4, borderColor: "#A8B8D9" }} />
                <Box component="div" sx={{ mx: 2, my: 2 }}>
                    <AppleSignInButton
                        type="button"
                        onClick={onSignInWithApple}
                        disabled={submitting}
                        disableElevation
                        sx={{
                            textTransform: "none",
                            fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif",
                            fontSize: 16,
                        }}
                    >
                        <Box
                            component="div"
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <AppleIcon sx={{ mb: 0.5, mr: 0.4 }} />
                            <span>Sign in with Apple</span>
                        </Box>
                    </AppleSignInButton>
                </Box>
                <Box component="div" sx={{ mx: 2, my: 2 }}>
                    <GoogleSignInButton
                        type="button"
                        onClick={onSignInWithGoogle}
                        disabled={submitting}
                        disableElevation
                        sx={{
                            textTransform: "none",
                            fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif",
                            fontSize: 16,
                        }}
                    >
                        <Box
                            component="div"
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <GoogleIcon sx={{ mr: 1 }} />
                            <span>Sign in with Google</span>
                        </Box>
                    </GoogleSignInButton>
                </Box>
            </Grid>
        </Grid>
    );
}
