import * as React from "react";
import {
    Navigate,
    Routes,
    Route,
    Link as RouterLink,
    useNavigate,
} from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

import volleyIconImg from "../../static/img/logo-volley-horizontal-white.png";

import AuthAction from "./AuthAction";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

export default function Auth(): JSX.Element {
    const navigate = useNavigate();

    return (
        <>
            <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, flexGrow: 1 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="back"
                        sx={{ mr: 2 }}
                        onClick={() => navigate("/")}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="back"
                        sx={{
                            m: 0,
                            p: 0,
                            flexGrow: 1,
                            // to center volley icon, remove space back button takes
                            ml: "-60px",
                        }}
                        component={RouterLink}
                        to="/"
                    >
                        <img src={volleyIconImg as string} width="130" alt="Volley logo" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ my: 4 }}>
                <Container maxWidth="xs">
                    <Toolbar />
                    <Routes>
                        <Route path="/action" element={<AuthAction />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="*" element={<Navigate replace to="/" />} />
                    </Routes>
                </Container>
            </Box>
        </>
    );
}
