import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Badge from "@mui/material/Badge";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { ProPartnerLocationWithRelations } from "@volley/data/dist/types/pros-partners-locations";

import { fetchApi } from "../util";
import { logFetchError } from "../util/fetchApi";

import APTAIdDialog from "./PTI/Dialogs/APTAIdDialog";
import { getLastSeenLocal } from "./Share/trackLastSeen";
import NavigationCard, { NavigationCardDef } from "./common/NavigationCard";
import UserPage from "./common/UserPage";
import { useSelectedSport } from "./common/context/sport";
import { useCurrentUser } from "./hooks/currentUser";

interface HomeButton {
    feature?: string;
    component: JSX.Element;
}

function DarkButton(props: ButtonProps) {
    const { sx, children, ...rest } = props;
    return (
        <Button
            variant="contained"
            color="primary"
            sx={{
                wordBreak: "break-word",
                textAlign: "center",
                boxShadow: 6,
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Button>
    );
}

const GreyButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#DCD5D5",
    "&:hover": {
        backgroundColor: "#C5B9B9",
    },
}));

function LightButton(props: ButtonProps) {
    const { sx, children, ...rest } = props;
    return (
        <GreyButton
            variant="contained"
            sx={{
                boxShadow: 6,
                wordBreak: "break-word",
                textAlign: "center",
                ...sx,
            }}
            {...rest}
        >
            {children}
        </GreyButton>
    );
}

function Home(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as { shareSucceeded?: boolean } | undefined;
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const { features, isAdmin, isPro } = useCurrentUser();
    const { selected: selectedSport } = useSelectedSport();

    const [newShared, setNewShared] = React.useState(0);
    const [displayClubSummary, setDisplayClubSummary] = React.useState(false);

    const [aptaDialogOpen, setAptaDialogOpen] = React.useState(false);
    const navigateToApta = React.useCallback(async (playerIdArg?: string) => {
        let playerId = playerIdArg;
        if (!playerId) {
            try {
                const res = await fetchApi<{ playerId: string }>("/api/pti/player-id/mine");
                ({ playerId } = res);
            } catch (err) {
                setAptaDialogOpen(true);
                return;
            }
        }

        navigate("/player-pti", { state: { aptaPlayerId: parseInt(playerId, 10) } });
    }, [navigate]);
    const onAptaDialogClose = React.useCallback(async (playerId?: string) => {
        setAptaDialogOpen(false);
        if (playerId) await navigateToApta(playerId);
    }, [navigateToApta]);

    React.useEffect(() => {
        if (isAdmin()) {
            setDisplayClubSummary(true);
        } else if (isPro()) {
            fetchApi<ProPartnerLocationWithRelations[]>("/api/partners")
                .then((locAssgResp) => {
                    // Pro must be assigned to a location by Volley to view feature
                    if (locAssgResp && locAssgResp.length > 0) {
                        setDisplayClubSummary(true);
                    }
                })
                // Action or message to user is probably not needed if call fails
                .catch((error) => logFetchError(error, "GET /api/partners failed in Home component"));
        } // else don't allow anyone to view this feature
    }, [isAdmin, isPro]);

    React.useEffect(() => {
        async function fetchNewShared() {
            if (!document.hidden) {
                let url = "/api/sessions/history/new";
                const local = getLastSeenLocal();
                if (local) {
                    url = `${url}?after=${local}`;
                }
                const result = await fetchApi<{ newCount: number, latest: number | null }>(url);
                return result;
            }
            return { newCount: 0, latest: null };
        }

        const handle = setInterval(() => {
            fetchNewShared()
                .then((result) => {
                    setNewShared(result.newCount);
                })
                .catch((e) => logFetchError(e));
        }, 5_000);

        fetchNewShared()
            .then((result) => {
                setNewShared(result.newCount);
            })
            .catch((e) => logFetchError(e));

        return () => clearInterval(handle);
    }, []);

    React.useEffect(() => {
        if (locationState) {
            setShowSnackbar(true);
        }
    }, [locationState]);

    const snackbarMessage = React.useMemo(() => {
        if (locationState) {
            if (locationState.shareSucceeded) {
                return "New workouts added to your history!";
            }
            return "There was an issue adding your shared workouts.";
        }

        return undefined;
    }, [locationState]);

    const snackbarAction = React.useMemo(() => {
        if (locationState) {
            if (locationState.shareSucceeded) {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => {
                            // clear the location state so hitting back from history
                            // page won't display this toast again
                            navigate("/", { replace: true, state: undefined });
                            navigate("/content/history-stats/mine");
                        }}
                    >
                        View
                    </Button>
                );
            }

            return (
                <Button
                    variant="contained"
                    color="info"
                    onClick={() => navigate("/", { replace: true, state: undefined })}
                >
                    Close
                </Button>
            );
        }

        return undefined;
    }, [locationState, navigate]);

    const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setShowSnackbar(false);
    };

    const topRowCards: HomeButton[] = [
        {
            component: (
                <DarkButton
                    key="workout-programs"
                    sx={{
                        height: "120px",
                        width: "100%",
                    }}
                    onClick={() => navigate("/content/apps/workouts")}
                >
                    <Stack>
                        <Typography variant="button">Multi-Shot</Typography>
                        <Typography variant="button">Workouts</Typography>
                    </Stack>
                </DarkButton>
            ),
        },
    ];

    const middleRow: HomeButton[] = [
        {
            feature: "GUIDED",
            component: (
                <DarkButton
                    key="guided"
                    sx={{
                        height: "120px",
                        width: "45%",
                        fontSize: "12px",
                    }}
                    onClick={() => navigate("/content/apps/workouts/plugin/play/5")}
                >
                    <Stack>
                        <Typography variant="button">Single-Shot</Typography>
                        <Typography variant="button">Workouts</Typography>
                    </Stack>
                </DarkButton>
            ),
        },
        {
            feature: "CREATOR",
            component: (
                <LightButton
                    key="my-workouts"
                    sx={{
                        height: "120px",
                        width: "45%",
                    }}
                    onClick={() => navigate("/content/apps/workouts/user")}
                >
                    <Stack>
                        <Typography variant="button">My</Typography>
                        <Typography variant="button">Workouts</Typography>
                    </Stack>
                </LightButton>
            ),
        },
    ];

    const bottomRow: HomeButton[] = [
        {
            component: (
                <Badge
                    key="history-stats"
                    color="secondary"
                    badgeContent={newShared}
                    sx={{
                        width: "100%",
                        height: "80px",
                    }}
                >
                    <LightButton
                        sx={{
                            height: "100%",
                            width: "100%",
                            fontSize: "12px",
                        }}
                        onClick={() => navigate("/content/history-stats/mine")}
                    >
                        <Stack>
                            <Typography variant="button">Stats</Typography>
                            <Typography variant="button">&amp; History</Typography>
                        </Stack>
                    </LightButton>
                </Badge>
            ),
        },
    ];

    const adminCards: NavigationCardDef[] = [
        {
            title: "Point-and-Click Lob Application",
            description: "Click a target on the court and launch a lob to that point",
            link: "/content/apps/workouts/experimental/lobapp",
        },
        {
            title: "Responsive Play",
            description: "use single player position information to throw ball away from player",
            link: "/content/apps/workouts/experimental/responsive",
        },
        {
            title: "Third Shot Dink Game",
            description: "Play kitchen drop game",
            link: "/content/apps/workouts/experimental/pickleball",
        },
        {
            title: "Serve and Volley App",
            description: "Realistic training to improve your serve and volley",
            link: "/content/apps/workouts/experimental/serveandvolley",
        },
    ];

    return (
        <>
            <Stack spacing={3}>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                >
                    {
                        topRowCards
                            .filter((c) => c.feature === undefined || features.includes(c.feature))
                            .map((c) => (c.component))
                    }
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    {
                        middleRow
                            .filter((c) => c.feature === undefined || features.includes(c.feature))
                            .map((c) => (c.component))
                    }
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    {
                        bottomRow
                            .filter((c) => c.feature === undefined || features.includes(c.feature))
                            .map((c) => (c.component))
                    }
                </Stack>
                {
                    features.includes("RESPONSIVE_PLAY") && (
                        <LightButton
                            fullWidth
                            onClick={() => navigate(adminCards[1].link)}
                            sx={{
                                height: "80px",
                            }}
                        >
                            {adminCards[1].title}
                        </LightButton>
                    )
                }
                {
                    features.includes("PICKLEBALL_GAME") && selectedSport === "PICKLEBALL" && (
                        <LightButton
                            fullWidth
                            onClick={() => navigate(adminCards[2].link)}
                            sx={{
                                height: "80px",
                            }}
                        >
                            {adminCards[2].title}
                        </LightButton>
                    )
                }
                {selectedSport === "PLATFORM_TENNIS" && features.includes("PTI") && (
                    <LightButton
                        fullWidth
                        onClick={() => navigateToApta()}
                        sx={{ height: "80px" }}
                    >
                        PTI
                    </LightButton>
                )}
                {
                    features.includes("SERVE_AND_VOLLEY") && selectedSport === "PLATFORM_TENNIS" && (
                        <LightButton
                            fullWidth
                            onClick={() => navigate(adminCards[3].link)}
                            sx={{
                                height: "80px",
                            }}
                        >
                            {adminCards[3].title}
                        </LightButton>
                    )
                }
                {
                    displayClubSummary && (
                        <LightButton
                            fullWidth
                            onClick={() => navigate("/content/club-summary")}
                            sx={{
                                height: "80px",
                            }}
                        >
                            Club Summary
                        </LightButton>
                    )
                }
                <LightButton
                    fullWidth
                    onClick={() => navigate("/account")}
                    sx={{
                        height: "80px",
                    }}
                >
                    View Account
                </LightButton>
                {isAdmin() && (
                    <Stack spacing={3} sx={{ paddingBottom: "40px" }}>
                        <Typography variant="h3" color="primary.main">Admin Features</Typography>
                        <Divider />
                        {adminCards.filter((c) => c.feature === undefined || features.includes(c.feature))
                            .map((c) => (
                                <NavigationCard key={c.title} card={c} />
                            ))}
                    </Stack>
                )}
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showSnackbar}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert
                    severity="info"
                    action={snackbarAction}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <APTAIdDialog
                open={aptaDialogOpen}
                onClose={onAptaDialogClose}
            />
        </>
    );
}

export default function HomePage(): JSX.Element {
    return (
        <UserPage>
            <Home />
        </UserPage>
    );
}
