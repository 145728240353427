import * as React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export function InfoListItem(
    { children, helpHref }: { children: React.ReactNode; helpHref?: string | null },
): JSX.Element {
    return (
        <ListItem sx={{ py: 2, backgroundColor: (theme) => theme.palette.common.white }}>
            <ListItemText>
                {children}
            </ListItemText>
            {!!helpHref && (
                <IconButton component={Link} href={helpHref} rel="noopener" target="_blank">
                    <HelpIcon color="disabled" />
                </IconButton>
            )}
        </ListItem>
    );
}
InfoListItem.defaultProps = { helpHref: null };

export function ErrorListItem(
    { children, helpHref }: { children: React.ReactNode; helpHref?: string | null },
): JSX.Element {
    return (
        <ListItem sx={{ py: 2 }}>
            <ListItemIcon>
                <ErrorIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
                {children}
            </ListItemText>
            {!!helpHref && (
                <IconButton component={Link} href={helpHref} rel="noopener" target="_blank">
                    <HelpIcon color="disabled" />
                </IconButton>
            )}
        </ListItem>
    );
}
ErrorListItem.defaultProps = { helpHref: null };

export function ActionListItem(
    {
        children, action, priority = true, icon,
    }:
    { children: React.ReactNode; action: () => void; priority?: boolean; icon?: React.ReactNode },
): JSX.Element {
    return (
        <ListItem component={Paper} elevation={priority ? 4 : 0} disablePadding>
            <ListItemButton
                onClick={action}
                sx={{
                    py: 2,
                    backgroundColor: (t) => (priority ? t.palette.secondary.main : t.palette.background.paper),
                    "&:hover": {
                        backgroundColor: (t) => (priority ? t.palette.secondary.main : t.palette.background.paper),
                    },
                }}
            >
                {icon && (
                    <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                        {icon}
                    </ListItemIcon>
                )}
                <ListItemText>
                    <Typography variant="h4">
                        {children}
                    </Typography>
                </ListItemText>
                <ListItemIcon sx={{ justifyContent: "flex-end", minWidth: "24px" }}>
                    <ChevronRightIcon color="primary" />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
    );
}
ActionListItem.defaultProps = { priority: true, icon: null };
