import * as React from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import SportPicker from "../SportPicker";
import { useSelectedSport } from "../common/context/sport";

import useDialog from "./useDialog";

export default function SportUnavailableDialog(): JSX.Element {
    const { friendlyName } = useSelectedSport();
    const { setDialogType } = useDialog();

    const content = [
        `This trainer is not compatible with ${friendlyName}`,
        "Please select another sport.",
    ].join(". ");

    return (
        <>
            <DialogTitle
                sx={{ p: 2 }}
                color="primary.main"
            >
                {`Trainer Incompatible with ${friendlyName}`}
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Stack
                    spacing={1}
                    sx={{
                        width: "100%",
                    }}
                >
                    <SportPicker
                        backdrop="light"
                        fullWidth
                        showOnlyCompatible
                        afterSportChanged={() => { setDialogType(null); }}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => setDialogType("Finished")}
                    >
                        End Session
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}
