import { CampaignClubCreatePayload } from "@volley/data/dist/types/campaign-club";

export const defaultState: CampaignClubCreatePayload = {
    name: "",
    city: "",
    state: "",
};

type Action<K extends keyof CampaignClubCreatePayload> = {
    type: K;
    value: CampaignClubCreatePayload[K];
};

export default function reducer<K extends keyof CampaignClubCreatePayload>(
    state: CampaignClubCreatePayload,
    action: Action<K>,
): CampaignClubCreatePayload {
    return { ...state, [action.type]: action.value };
}
