import * as React from "react";

import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useStatus } from "../../hooks/status";
import useDialog from "../useDialog";

import { ActionListItem } from "./listItems";

export default function BatteryListItem(): JSX.Element {
    const { setDialogType } = useDialog();
    const { status } = useStatus();
    const batteryLevel = status?.trainer.battery.level;

    if (status?.hardwarePlatform === "atom" && status.fault?.failures[0].type === "LowBattery") {
        return (
            <ActionListItem
                action={() => setDialogType("ReplaceBatteries")}
                icon={<Battery1BarIcon color="error" fontSize="medium" />}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">
                        Low Battery:
                    </Typography>
                    <Typography
                        variant="h4"
                        color="text.primary"
                    >
                        Exchange
                    </Typography>
                </Stack>
            </ActionListItem>
        );
    }

    return (
        <ActionListItem
            action={() => setDialogType("ReplaceBatteries")}
            priority={false}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4">
                    Battery:
                </Typography>
                <Typography
                    variant="h4"
                    color="success.main"
                >
                    {` ${batteryLevel ?? ""}%`}
                </Typography>
            </Stack>
        </ActionListItem>
    );
}
