import * as React from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { OffsetResult } from "@volley/data";
import { CampaignClub, EmailConfirmationResult } from "@volley/data/dist/types/campaign-club";

import fetchApi from "../../util/fetchApi";

export default function CampaignClubConfirm(): JSX.Element {
    const navigate = useNavigate();
    const { token } = useParams<"token">();
    const [email, setEmail] = React.useState<string | null>(null);
    const [referredByMemberTrackingId, setReferredByMemberTrackingId] = React.useState<string | null>(null);
    const [clubs, setClubs] = React.useState<CampaignClub[]>([]);
    const [clubToJoin, setClubToJoin] = React.useState<CampaignClub | null>(null);
    const [clubSearch, setClubSearch] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        setErrorMessage("");
        if (token) {
            fetchApi<EmailConfirmationResult>("/api/campaign-clubs/email-confirmations", "PUT", { token })
                .then((result) => {
                    // If a member exists for this email, go the the member's club dashboard
                    if (result.existingMemberTrackingId) {
                        navigate(`../t/member_${result.existingMemberTrackingId}`, { replace: true });
                    // If the confirmation contains a referral, start the join process
                    } else if (result.referredByMemberTrackingId && result.campaignClubToJoinId) {
                        navigate("../join", {
                            replace: true,
                            state: {
                                email: result.email,
                                campaignClubId: result.campaignClubToJoinId,
                                campaignClubName: result.campaignClubToJoinName,
                                referredByMemberTrackingId: result.referredByMemberTrackingId,
                            },
                        });
                    // Otherwise let the member choose a club or create a new one
                    } else {
                        setEmail(result.email);
                        setReferredByMemberTrackingId(result.referredByMemberTrackingId);
                    }
                })
                .catch((err) => {
                    setErrorMessage((err as Error).message);
                });
        }
    }, [navigate, token]);

    React.useEffect(() => {
        if (email) {
            fetchApi<OffsetResult<CampaignClub>>("/api/campaign-clubs?limit=500&offset=0")
                .then((result) => {
                    setClubs(result.result);
                })
                .catch((err) => {
                    setErrorMessage((err as Error).message);
                });
        }
    }, [email]);

    const filteredClubs = React.useMemo(() => {
        if (clubSearch) {
            return clubs.filter((c) => c.name.toLocaleLowerCase().includes(clubSearch.toLocaleLowerCase().trim()));
        }
        return clubs;
    }, [clubs, clubSearch]);

    if (!token) {
        return (
            <Typography color="error.main">
                Invalid confirmation link. Please contact Volley for assistance.
            </Typography>
        );
    }

    if (errorMessage) {
        return <Typography color="error.main">{errorMessage}</Typography>;
    }

    if (!email) {
        return (
            <Box component="div" display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h3" component="h1" color="primary">Email confirmed!</Typography>
            <Typography>
                Please select your club, or if it’s not listed, you can add it.
            </Typography>
            {clubs.length > 1 && (
                <TextField
                    id="club-search"
                    type="search"
                    label="Search for your club"
                    placeholder="My Club"
                    value={clubSearch}
                    onChange={(e) => setClubSearch(e.target.value)}
                />
            )}
            <List>
                {filteredClubs.map((club) => (
                    <ListItem key={club.id}>
                        <ListItemButton onClick={() => setClubToJoin(club)}>
                            <ListItemText>
                                {`${club.name}, ${club.city}, ${club.state}`}
                            </ListItemText>
                            <ChevronRightIcon />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Button
                variant="contained"
                color="secondary"
                sx={{ color: "primary.main" }}
                startIcon={<AddIcon />}
                component={RouterLink}
                to="../create"
                state={{ email }}
            >
                Add My Club
            </Button>
            <Dialog open={clubToJoin !== null} onClose={() => setClubToJoin(null)}>
                {clubToJoin && (
                    <>
                        <DialogTitle>{`Join ${clubToJoin?.name ?? "This Club"}?`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to join
                                {" "}
                                <strong>{clubToJoin.name}</strong>
                                {" in "}
                                <strong>{`${clubToJoin.city}, ${clubToJoin.state}`}</strong>
                                ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setClubToJoin(null)}>Cancel</Button>
                            <Button
                                component={RouterLink}
                                to="../join"
                                state={{
                                    email,
                                    referredByMemberTrackingId,
                                    campaignClubId: clubToJoin.id,
                                }}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Stack>
    );
}
