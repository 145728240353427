import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Sport, trainerCompatibleWithSport, useSelectedSport } from "./common/context/sport";
import { useStatus } from "./hooks/status";

export interface SportPickerProps {
    backdrop: "light" | "dark";
    fullWidth: boolean;
    showOnlyCompatible?: boolean;
    afterSportChanged?: (sport: Sport) => void;
    limitedList?: Record<string, string>;
}

export default function SportPicker({
    backdrop,
    fullWidth,
    showOnlyCompatible,
    afterSportChanged,
    limitedList,
}: SportPickerProps): JSX.Element {
    const {
        selected,
        available,
        hasSelected,
        updateSelected,
    } = useSelectedSport();
    const { status } = useStatus();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

    const dropShadow = backdrop === "dark"
        ? "#096EE5 4px 0 4px"
        : undefined;

    const color = backdrop === "dark"
        ? "white"
        : "primary.main";

    const icon = React.useMemo(() => {
        if (anchorEl) {
            return (
                <KeyboardArrowUpIcon
                    sx={{
                        fontSize: "18px",
                        dropShadow,
                    }}
                />
            );
        }

        return (
            <KeyboardArrowDownIcon
                sx={{
                    fontSize: "18px",
                    dropShadow,
                }}
            />
        );
    }, [anchorEl, dropShadow]);

    const buttonText = showOnlyCompatible && !hasSelected
        ? "Select Sport"
        : available[selected];

    return (
        <>
            <Button
                fullWidth={fullWidth}
                onClick={(e) => {
                    if (anchorEl) {
                        setAnchorEl(undefined);
                    } else {
                        setAnchorEl(e.currentTarget);
                    }
                }}
                disableElevation
                sx={{
                    color,
                    textShadow: dropShadow,
                    fontSize: "18px",
                }}
                startIcon={icon}
            >
                {buttonText}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={anchorEl !== undefined}
                onClose={() => setAnchorEl(undefined)}
            >
                {Object.keys(limitedList ?? available).filter((a) => {
                    if (showOnlyCompatible) {
                        return trainerCompatibleWithSport(a as Sport, status);
                    }

                    return true;
                }).map((k) => {
                    let label = limitedList ? limitedList[k as Sport] : available[k as Sport];
                    if (label === "Pickleball") {
                        label += " (Requires Pickleball Trainer)";
                    }
                    return (
                        <MenuItem
                            key={k}
                            onClick={() => {
                                updateSelected(k as Sport);
                                setAnchorEl(undefined);
                                if (afterSportChanged) {
                                    afterSportChanged(k as Sport);
                                }
                            }}
                            sx={{
                                color: "primary.main",
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}

SportPicker.defaultProps = {
    afterSportChanged: undefined,
    showOnlyCompatible: false,
    limitedList: undefined,
};
