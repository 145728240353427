/* eslint-disable import/prefer-default-export */
import * as React from "react";

import type { WorkoutStatus } from "@volley/shared/coach-models";

import logger from "../../log";

import { useStatus } from "./status";

export function useAppWorkoutStatus(): WorkoutStatus {
    const { status, removeRapidPollCheck, startRapidPoll } = useStatus();

    React.useEffect(() => {
        logger.info("Starting Rapid Polling for Workout Status");
        startRapidPoll(1000, () => false, "workouts");
        return () => {
            removeRapidPollCheck("workouts");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return status?.workouts ?? {
        appErrors: [],
        currentWorkout: null,
        diagnostics: {},
        recordingState: "inactive",
        playState: "stopped",
        serviceState: "Running",
    };
}
