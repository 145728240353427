import * as React from "react";

import BallJamPreamble from "../BallJamPreamble";
import LowerHeadDialog from "../LowerHeadDialog";
import PowerUpDialog from "../PowerUpDialog";
import RemoveBallsDialog from "../RemoveBallsDialog";
import useDialog from "../useDialog";

const enum BallJamSteps {
    Start,
    LowerHead,
    Clear,
    PowerUp,
    Calibrate,
    Done,
}

interface BallJamFlowProps {
    noPowerDrop?: boolean;
}
export default function BallJamFlow({ noPowerDrop = false }: BallJamFlowProps): JSX.Element | null {
    const { setDialogType } = useDialog();
    const [step, setStep] = React.useState<BallJamSteps>(noPowerDrop ? BallJamSteps.Start : BallJamSteps.LowerHead);

    const onError = React.useCallback(() => {
        setDialogType("FatalError");
    }, [setDialogType]);

    const onBallJamClear = React.useCallback(() => {
        setStep(BallJamSteps.PowerUp);
    }, [setStep]);

    if (step === BallJamSteps.LowerHead) {
        return (
            <LowerHeadDialog
                reason="balljam"
                onLowered={() => setStep(BallJamSteps.Clear)}
                onStopped={() => setDialogType(null)}
                onError={onError}
            />
        );
    }

    if (step === BallJamSteps.Start) {
        return (
            <BallJamPreamble
                onClear={() => setStep(BallJamSteps.LowerHead)}
                onRetry={() => setStep(BallJamSteps.Done)}
            />
        );
    }

    if (step === BallJamSteps.Clear) {
        return (<RemoveBallsDialog onClear={onBallJamClear} />);
    }

    if (step === BallJamSteps.PowerUp) {
        return <PowerUpDialog onPoweredUp={() => setStep(BallJamSteps.Done)} />;
    }

    if (step === BallJamSteps.Done) {
        setDialogType(null);
    }

    return null;
}

BallJamFlow.defaultProps = {
    noPowerDrop: false,
};
