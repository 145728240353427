/* eslint-disable import/no-cycle */
import { LogsInitConfiguration } from "@datadog/browser-logs";
import { RumInitConfiguration } from "@datadog/browser-rum";

import { getEnv } from "./util";

const env = getEnv();

const firebaseProduction = {
    apiKey: "AIzaSyBqpwriBEqjp1kVG0_PkruuWBg4VWQQirc",
    authDomain: "volley-web-production.firebaseapp.com",
    projectId: "volley-web-production",
    messagingSenderId: "884471169963",
    appId: "1:884471169963:web:65e228d70e0142ade948ee",
};

const firebaseStaging = {
    apiKey: "AIzaSyC3aFyszleli58YwXsYwidflsQGfFQnjns",
    authDomain: "volley-web-staging.firebaseapp.com",
    projectId: "volley-web-staging",
    messagingSenderId: "1070126318692",
    appId: "1:1070126318692:web:be4c3943610fd9172a4b71",
};

const firebaseRc = {
    apiKey: "AIzaSyAI2sRJcifXFyoAZmDeKF8upK2-OczW7Vg",
    authDomain: "volley-web-rc.firebaseapp.com",
    projectId: "volley-web-rc",
    messagingSenderId: "191844139284",
    appId: "1:191844139284:web:562e9f50febf9de46d4201",
};

const datadogLogging: LogsInitConfiguration = {
    clientToken: "pubbe07b92048ab4c7e176d8d18d4678af9",
    site: "us5.datadoghq.com",
    forwardErrorsToLogs: env !== "local", // forward errors if we're not local
    sessionSampleRate: 100,
};

const datadogRum: RumInitConfiguration = {
    applicationId: "0df780c6-2fb1-4255-911a-85a3fbb83196",
    clientToken: "pubbe07b92048ab4c7e176d8d18d4678af9",
    site: "us5.datadoghq.com",
    service: "webby-app",
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: "2.2.7", // TODO would be nice to make webby-app aware of version
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    trackResources: true,
    allowedTracingUrls: [
        "https://staging.onevolley.com",
        "https://rc.onevolley.com",
        "https://play.onevolley.com",
        "https://app.onevolley.com",
        "https://staging.getvolley.com",
        "https://rc.getvolley.com",
        "https://play.getvolley.com",
        "https://app.getvolley.com",
    ],
};

let firebase = firebaseStaging;
if (env === "rc") {
    firebase = firebaseRc;
} else if (env === "production") {
    firebase = firebaseProduction;
}

const config = {
    firebase,
    datadogRum,
    datadogLogging,
    env,
};

export default config;
