import * as React from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { useLogMountTime } from "../../log";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";
import useInterval from "../hooks/useInterval";

import useDialog from "./useDialog";

const MAX_RECONNECT_SECONDS = 5;
const SHOW_UNPAIR_MILLISECONDS = 3_000;

export default function ServiceUnavailableDialog(): JSX.Element {
    useLogMountTime("ServiceUnavailableDialog");

    const { networkStateCode } = useStatus();
    const { setDialogType } = useDialog();
    const { unpair } = usePairingContext();
    const [nextAttemptSeconds, setNextAttemptSeconds] = React.useState(MAX_RECONNECT_SECONDS);
    const firstRender = React.useRef(performance.now());

    const onClose = React.useCallback(() => {
        setDialogType(null);
    }, [setDialogType]);

    const onClickUnpair = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        unpair();
        setDialogType("ShutdownHardwareChecklist");
    }, [setDialogType, unpair]);

    useInterval(() => {
        setNextAttemptSeconds(nextAttemptSeconds === 1 ? MAX_RECONNECT_SECONDS : nextAttemptSeconds - 1);
    }, 1000);

    // Auto close this dialog if the network reconnects
    React.useEffect(() => {
        if (networkStateCode === "Connected") {
            onClose();
        }
    }, [networkStateCode, onClose]);

    const secondsDisplay = `${nextAttemptSeconds} second${nextAttemptSeconds > 1 ? "s" : ""}`;

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Network Connection Problem
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    <CircularProgress color="primary" />
                </DialogContentText>
                <DialogContentText>
                    {networkStateCode === "ServiceConnectionDown"
                        ? "We're sorry. Connection with the Volley service couldn't be established."
                        : `The trainer will attempt to reconnect in ${secondsDisplay}.`}
                </DialogContentText>
                <DialogContentText>
                    If a connection is not re-established, please return the trainer to the designated storage area.
                </DialogContentText>
                {(firstRender.current + SHOW_UNPAIR_MILLISECONDS < performance.now()) && (
                    <DialogContentText>
                        <Button onClick={onClickUnpair}>
                            Disconnect
                        </Button>
                    </DialogContentText>
                )}
            </DialogContent>
        </>
    );
}
